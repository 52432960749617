/**
 * Description of LogoutControl.js
 *
 * This module is a mapbox control that allows the user to logout of the map.
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */ 

class LogoutControl {
	constructor(logout = () => {}) {
		this.logout = logout;
	}

	onAdd(map) {
		const _this = this;
		_this.map = map;
		_this.logoutControl = document.createElement('div');
		_this.logoutImage = document.createElement('img');
		_this.logoutImage.className = 'mapboxgl-ctrl-bgwt logout';
		_this.logoutImage.id = 'logoutImage';
		_this.logoutImage.alt = 'Logout';
		_this.logoutImage.title = 'Logout / Return to Dashboard';
		_this.logoutImage.type = 'button';
		_this.logoutImage['aria-label'] = 'Logout';
		_this.logoutControl.appendChild(_this.logoutImage);
		_this.logoutControl.onclick = _this.logout;
		_this.container = document.createElement('div');
		_this.container.style.cursor = 'pointer';
		_this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-logoutControl';
		_this.container.appendChild(_this.logoutControl);
		return _this.container;
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container);
		this.map = undefined;
	}
}

export default LogoutControl;
