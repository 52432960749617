/**
 * Description of aois.js
 *
 * This module is a mapbox control
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import _ from 'lodash';
import { Constants } from '../components/Constants';
import { log } from '../components/Services';

class AOILibraryControl {
	constructor(locations, action, admin, username) {
		// this.locations = locations.sort((a, b) => (a.country > b.country ? 1 : a.country < b.country ? -1 : a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
		this.locations = locations;
		this.action = action;
		this.availableAOIsText = 'AOI Libary ...';
		this.availableAOIsFullText = `${this.availableAOIsText} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
		this.noAOIsText = 'No  AOIs';
		this.username = username;
		this.admin = admin;
	}

	onAdd(map) {
		const _this = this,
			loadingLocations = 'Loading AOI Library ...';

		_this.map = map;
		_this.doc = document;
		_this.AOILibraryControl = _this.doc.createElement('div');
		_this.AOILibraryControl.className = 'AOILibraryControl';
		_this.select = _this.doc.createElement('select');

		let option = _this.doc.createElement('option');

		if (_this.select.options.length > 0 && _.includes([_this.availableAOIsText, _this.noAOIsText], _this.select.options[0].text)) {
			_this.select.disabled = false;
			_this.select.options[0].text = _this.locations.length > 0 ? _this.availableAOIsText : loadingLocations;
			_this.customSelectSelected.innerHTML = _this.locations.length > 0 ? _this.availableAOIsFullText : loadingLocations;
			_this.customSelectItems[0].removeChild(_this.customSelectItems[0].children[0]);
		} else {
			option.text = _this.locations.length > 0 ? _this.availableAOIsText : loadingLocations;
		}

		option.value = 0;

		_this.select.add(option);
		_this.select.disabled = true;

		_this.AOILibraryControl.appendChild(_this.select);

		_this.customSelectSelected = _this.doc.createElement('div');
		_this.customSelectSelected.className = `select-selected`;
		_this.customSelectSelected.innerHTML = _this.select.options[_this.select.selectedIndex].innerHTML;

		_this.AOILibraryControl.appendChild(_this.customSelectSelected);

		_this.customSelect = _this.doc.createElement('div');
		_this.customSelect.className = `select-items select-hide`;

		let item = _this.doc.createElement('div');
		item.innerHTML = option.innerHTML;
		item.addEventListener('click', _this.itemSelect);
		_this.customSelect.appendChild(item);

		_this.AOILibraryControl.appendChild(_this.customSelect);
		_this.customSelectItems = _this.doc.getElementsByClassName('select-items');
		_this.customSelectSelected.addEventListener('click', _this.toggleCustomSelect);

		_this.doc.addEventListener('click', _this.closeAllSelect);
		_this.container = _this.doc.createElement('div');
		_this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-AOILibraryControl';
		_this.container.appendChild(_this.AOILibraryControl);
		return _this.container;
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container);
		this.map = undefined;
	}
	hide() {
		this.AOILibraryControl.classList.add('hidden');
	}

	show() {
		this.AOILibraryControl.classList.remove('hidden');
	}

	createLocationName = (location) => {
		let icon =
				(this.username !== location.owner && !location.publicAccess) || (this.username === location.owner && location.members && location.members.length > 0)
					? `<span className="share">&nbsp;<img src='shared.png' alt="Shared" style="height:12px"/>&nbsp;</sp>`
					: location.publicAccess
					? `<span className="share">&nbsp;<img src='globe.png' alt="Public"  style="height:12px"/>&nbsp;</span>`
					: '',
					priority= location.priority >= 100 ? 
					`<span className="priority">&nbsp;<img src='fire.png' alt="Priority"  style="height:12px"/>&nbsp;</span>`
					: '' ,
			text = `<div style="width: var(--bg-AOILibraryControl-width)" data-location="${location.name}"><span 
			style="float: left">${location.country} | ${location.name}</span><span style="float: right">${priority}</span> <span style="float: right">${icon}</span>`,
			tooltip = `Created by ${this.username !== location.owner ? location.owner.split('@')[0] : 'you'}\n${
				location.publicAccess
					? '\nPublicly Accessible'
					: this.username !== location.owner && _.includes(location?.members || [], this.username)
					? `\nShared to you`
					: this.username === location.owner || this.admin
					? location.members && location.members.length > 0
						? `\nShared to\n${location.members.map((_member) => `\n${Constants.BULLET_POINT} ${_member.split('@')[0]}`)}`
						: '\nPrivate'
					: ''
			}`;

		return [text, tooltip];
	};

	clearSelect = () => {
		if (this.doc.querySelector(`.AOILibraryControl .same-as-selected`)) {
			this.doc.querySelector(`.AOILibraryControl .same-as-selected`).classList.remove('same-as-selected');
		}

		this.doc.querySelector(`.AOILibraryControl .select-selected`).innerHTML = this.availableAOIsFullText;
		this.select.value = 0;
	};

	add = (location) => {
		if (
			this.select &&
			!_.includes(
				_.toArray(this.select.options).map((option) => option.value),
				location.locationsId?.toString()
			)
		) {
			if (_.includes([this.availableAOIsText, this.noAOIsText], this.select.options[0].text)) {
				this.select.disabled = false;
				this.customSelectSelected.innerHTML = this.availableAOIsFullText;

				if (_.includes([this.noAOIsText, this.availableAOIsText], this.customSelectItems[0].children[0].innerHTML)) {
					this.customSelectItems[0].removeChild(this.customSelectItems[0].children[0]);
				}
			}

			const option = this.doc.createElement('option'),
				item = this.doc.createElement('div'),
				[text, tooltip] = this.createLocationName(location);

			option.text = text;
			option.value = location.locationsId;
			option.title = tooltip;
			option.setAttribute('data-location', location.name);

			this.select.add(
				option,
				_.toArray(this.select.options).findIndex((_option) => _option.text > location.name)
			);

			item.innerHTML = text;
			item.title = tooltip;
			item.setAttribute('data-location', location.name);
			item.addEventListener('click', this.itemSelect);

			this.customSelectItems[0].insertBefore(
				item,
				_.toArray(this.customSelectItems[0].children).find((_child) => _child.textContent > text)
			);
		}
	};

	update = (location) => {
		const sameAsSelected = this.doc.querySelector(`.AOILibraryControl .same-as-selected`);

		if (sameAsSelected) {
			sameAsSelected.classList.remove('same-as-selected');
		}

		let selectedIndex = _.toArray(this.select)
				.filter((_option) => typeof _option === 'object' && _option.value !== '0')
				.findIndex((_option) => _option.value === location.locationsId),
			[text, tooltip] = this.createLocationName(location);

		this.select.selectedIndex = selectedIndex;
		this.select.options[selectedIndex].innerHTML = text;
		this.select.options[selectedIndex].title = tooltip;
		this.select.options[selectedIndex].setAttribute('data-location', location.name);
		this.customSelectSelected.innerHTML = text;
		this.customSelectItems[0].children[selectedIndex].innerHTML = text;
		this.customSelectItems[0].children[selectedIndex].title = tooltip;
		this.customSelectItems[0].children[selectedIndex].setAttribute('data-location', location.name);
		this.customSelectItems[0].children[selectedIndex].classList.add('same-as-selected');
	};

	remove = (locationsId) => {
		const sameAsSelected = this.doc.querySelector(`.AOILibraryControl .same-as-selected`);

		if (sameAsSelected) {
			sameAsSelected.classList.remove('same-as-selected');
		}

		let customSelectedIndex = _.toArray(this.select)
				.filter((_option) => typeof _option === 'object' && _option.value !== '0')
				.findIndex((_option) => _option.value === locationsId),
			selectedIndex = _.toArray(this.select).findIndex((_option) => _option.value === locationsId);

		this.customSelectItems[0].removeChild(this.customSelectItems[0].children[customSelectedIndex]);
		this.select.remove(selectedIndex);

		if (this.select.options.length === 0) {
			this.setNoAOIs();
		}
	};

	itemSelect = (event) => {
		const item = event.target,
			sameAsSelected = this.doc.querySelector(`.AOILibraryControl .same-as-selected`),
			selected = _.toArray(this.select.options).find((_option) => _option.dataset.location === item.parentElement.dataset.location);

		if (selected?.value !== '0') {
			this.clearSelect();

			this.select.selectedIndex = selected;
			this.customSelectSelected.innerHTML = item.innerHTML;

			if (sameAsSelected) {
				sameAsSelected.removeAttribute('class');
			}

			item.className = `same-as-selected`;
			this.hide();

			this.action(selected.value, 'AOILibraryControl');
			this.customSelectSelected.click();
			this.closeAllSelect(item);
		} else {
			log(`AOILibraryControl::itemSelect: selected.value is 0 for item.id`);
			event.preventDefault();
			event.stopPropagation();
		}
	};

	closeAllSelect = (element) => {
		let selected = [];

		_.toArray(this.customSelectSelected).forEach((selectedSelect, index) => {
			if (element === selectedSelect) {
				selected.push(index);
			} else {
				selectedSelect.classList.remove('select-arrow-active');
			}
		});

		_.toArray(this.customSelectItems).forEach((item, index) => {
			if (selected.indexOf(index)) {
				item.classList.add('select-hide');
			}
		});
	};

	toggleCustomSelect = (event) => {
		const select = event.target;
		event.stopPropagation();
		this.closeAllSelect(select);

		if (!_.includes(select.classList, 'select-arrow-active')) {
			select.nextSibling.classList.toggle('select-hide');
			this.doc.querySelector('.select-selected').setAttribute('style', 'border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important');
		} else {
			this.doc.querySelector('.select-selected').setAttribute('style', 'border-bottom-left-radius: 4px !important; border-bottom-right-radius:: 4px !important');
		}

		select.classList.toggle('select-arrow-active');
	};

	setNoAOIs = () => {
		const option = this.doc.createElement('option');
		option.text = 'No available locations';
		option.value = 0;
		option.selected = true;
		this.select.disabled = true;
		this.customSelectSelected.innerHTML = option.text;
		this.select.add(option, 0);
	};
}

export default AOILibraryControl;
