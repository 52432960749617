import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export default function MaterialTooltipIconButton(props) {
	const { title = '', className, iconButtonStyle = {}, isDisabled = false, edge = 'end', iconSizeStyle = {}, fontSize = 'small', Icon, onClick = () => {}, spanStyle = {} } = props;
	return (
		<Tooltip title={title} className={className}>
			<span style={spanStyle}>
				<IconButton onClick={onClick} disabled={isDisabled} edge={edge} style={iconButtonStyle}>
					<Icon style={iconSizeStyle} fontSize={fontSize} />
				</IconButton>
			</span>
		</Tooltip>
	);
}
