import './index.css';
import React, { useEffect, useState } from 'react';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MaterialTooltipIconButton from '../../shared/UI/components/materialTooltipIconButton/MaterialTooltipIconButton';
import dayjs from 'dayjs';
import { format, parseISO, addDays, subDays } from 'date-fns';
import { Constants, PLANET_MENU_VALUE, PRO_USER_DEFAULT_LAYERS, SENTINEL3, STANDARD_USER_DEFAULT_LAYERS } from '../Constants';
import CustomizedDate from './components/customizedDate/CustomizedDate';
import SatteliteSelect from './components/satteliteSelect/SatteliteSelect';
import MaterialAutoComplete from '../../shared/UI/components/materialAutoComplete/MaterialAutoComplete';
import { getSatelliteKeyName } from '../../services/mapboxServices';
import { InputAdornment } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { MobileDatePicker } from '@mui/x-date-pickers';

function TopBar(props) {
	const {
		options,
		setLocation,
		logoutAction,
		selectedLocation,
		editLocation,
		setModalOpened,
		allowExport,
		images,
		setStartMonthFilter,
		selectedLayers = [],
		showLayersAfterDateChange,
		satteLiteView,
		setSatteliteView,
		trendData,
		defaultDate,
		showDefaultLayers,
		setShowDefaultLayers,
		lastDateDisplayed,
		isProUser,
		setSelectedLayers,
		removeAllLayers,
		selectedDate,
		setSelectedDate,
		userCanEdit,
		user,
		loading,
		setLoading,
		setConfirmModalState,
		isTablet,
	} = props;

	const iconSizeStyle = {
		width: '16px',
		height: '16px',
	};

	const isDisabled = selectedLocation?.name ? false : true;

	const settingsTooltip = 'settings';
	const exportTooltip = allowExport ? 'export' : 'export not allowed';
	const logoutTooltip = 'Logout';
	// const feedbackTooltip = 'feedback';

	let minDate = dayjs(format(selectedLocation.dateRange[0].start_date, Constants.DATE_FORMATS.DATE_FORMAT));
	// let maxDate = dayjs(format(selectedLocation.dateRange[0].end_date, Constants.DATE_FORMATS.DATE_FORMAT));
	let maxDate = dayjs(format(new Date(), Constants.DATE_FORMATS.DATE_FORMAT));
	// let defaultDate = format(subDays(new Date(), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	const [selectedMonth, setSelectedMonth] = useState('');

	const shouldDisableDate = (date) => {
		if (!date) return;
		let formattedDate = format(date['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

		if (satteLiteView.includes('Sentinel')) {
			//check valid dates in the selectedLocation for sentinel
			// return images[formattedDate]?.thumbnails['trueColour'].caption;
			if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites['S3'] && selectedLocation.satellites['S3'].dates) {
				let index = selectedLocation.satellites['S3'].dates.indexOf(formattedDate);
				return index >= 0 ? false : true;
			}
		} else if (satteLiteView.includes('Planet')) {
			//check valid dates in the selectedLocation for planet

			if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites['PSD'] && selectedLocation.satellites['PSD'].dates) {
				let index = selectedLocation.satellites['PSD'].dates.indexOf(formattedDate);
				return index >= 0 ? false : true;
			}

			// if (images[formattedDate] && images[formattedDate].imagery && images[formattedDate].imagery.urls) {
			// 	return images[formattedDate].imagery.urls.planet_url ? false : true;
			// } else {
			// 	return true;
			// }
		} else {
			return;
		}
	};

	const onMonthChange = async (data) => {
		if (!data) return;
		await setSelectedMonth(data);
		setStartMonthFilter(data['$d']);
	};

	useEffect(() => {
		const reset = async () => {
			if (showDefaultLayers && lastDateDisplayed) {
				if (!shouldDisableDate(defaultDate) && !satteLiteView.includes('Planet')) {
					if (!loading) {
						setLoading(true);
					}
					await showLayersAfterDateChange(selectedDate ? selectedDate['$d'] : defaultDate['$d']);
					setShowDefaultLayers(false);
					setLoading(false);
				}
			}
		};
		reset();
	}, [lastDateDisplayed, showDefaultLayers, defaultDate, satteLiteView, selectedDate, showDefaultLayers]);

	const onDayChange = async (data, view = '') => {
		if (!data) return;
		setSelectedMonth(data);
		setSelectedDate(data);
		let state = await setStartMonthFilter(data['$d']);

		if (selectedLayers.length > 0) {
			let newDate = data['$d'];
			let viewCopy = view === SENTINEL3 ? SENTINEL3 : view === PLANET_MENU_VALUE ? PLANET_MENU_VALUE : '';
			showLayersAfterDateChange(newDate, viewCopy, state);
		}
	};

	const handleChange = async (data) => {
		if (!data) {
			return;
		}
		if (selectedLocation?.locationsId !== data.locationsId) {
			const index = options.findIndex((el) => el.locationsId === data.locationsId);
			if (index >= 0 && options[index]) {
				setSelectedLayers(isProUser ? PRO_USER_DEFAULT_LAYERS : STANDARD_USER_DEFAULT_LAYERS);
				await setLocation(data.locationsId);
				setShowDefaultLayers(true);
				await setStartMonthFilter(selectedDate ? selectedDate['$d'] : defaultDate['$d']);
			}
		}
	};

	const locationsOptions = options.map((el) => {
		return {
			...el,
			value: el.locationsId,
			label: el.country + ' ' + el.name,
		};
	});

	let sattelitePlanetDisabled = true;
	if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites['PSD']) {
		sattelitePlanetDisabled = false;
	}

	const satteliteOptions = [
		{
			title: 'Sentinel 3 (300m)',
			value: SENTINEL3,
			disabled: false,
		},
		{
			title: 'Planet (3m)',
			value: PLANET_MENU_VALUE,
			disabled: sattelitePlanetDisabled,
		},
	];

	const RenderDate = (props) => {
		const { disabled, day } = props;
		let newProps = { ...props };
		let clouds = 0;
		let bloomspread = 0;
		let bloomlevel = 0;
		let formattedDate = format(day['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

		if (!disabled && trendData && trendData.length > 0) {
			let index = trendData.findIndex((data) => {
				let formatGivenDate = format(data.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
				return formatGivenDate === formattedDate;
			});
			if (trendData[index] && trendData[index].Clouds) {
				clouds = (Math.round(trendData[index].Clouds * 100) / 100).toFixed(2);
			}
			if (trendData[index] && trendData[index].BloomSpread) {
				bloomspread = (Math.round(trendData[index].BloomSpread * 100) / 100).toFixed(2);
			}

			if (trendData[index] && trendData[index].BloomLevel) {
				bloomlevel = (Math.round(trendData[index].BloomLevel * 100) / 100).toFixed(2);
			}

			if (index >= 0 && trendData[index]) {
				newProps = {
					...newProps,
					clouds: clouds,
					bloomspread: bloomspread,
					bloomlevel: bloomlevel,
				};
			}
		}

		let planetindex;
		let hasplanet = false;

		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites['PSD'] && selectedLocation.satellites['PSD'].dates) {
			planetindex = selectedLocation.satellites['PSD'].dates.indexOf(formattedDate);
			newProps = { ...newProps, planetindex: planetindex };
		}
		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites['PSD'] && selectedLocation.satellites['PSD'].dates && selectedLocation.satellites['PSD'].dates.length > 0) {
			hasplanet = 1;
			newProps = { ...newProps, planetindex: planetindex, hasplanet };
		}

		return <CustomizedDate {...newProps} />;
	};

	const continueToSuitableDate = async (data, newValue) => {
		if (data !== satteLiteView) {
			await removeAllLayers();
			// setShowDefaultLayers(true);
		}
		onDayChange(newValue, data);
	};

	const satelliteViewChanged = async (data) => {
		let formattedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		let index, formerIndex;

		const satelliteKey = getSatelliteKeyName(data);
		const formerSatelliteKey = getSatelliteKeyName(satteLiteView);

		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites[satelliteKey] && selectedLocation.satellites[satelliteKey].dates) {
			index = selectedLocation.satellites[satelliteKey].dates.indexOf(formattedDate);
			formerIndex = selectedLocation.satellites[formerSatelliteKey].dates.indexOf(formattedDate);
		}
		if (index >= 0) {
			if (data !== satteLiteView) {
				await setSatteliteView(data);

				await removeAllLayers();
				setShowDefaultLayers(true);
			}
			showLayersAfterDateChange(selectedDate ? selectedDate['$d'] : defaultDate['$d'], data);
		} else {
			let formerLEngth = selectedLocation.satellites[formerSatelliteKey].dates.length;
			let exitSearch = false;
			let newValue;
			let text = 'Planet';
			if (data === SENTINEL3) {
				text = 'Sentinel 3';
			}

			for (let i = 1; i < formerLEngth && !exitSearch; i++) {
				let nextValue = selectedLocation.satellites[formerSatelliteKey].dates[formerIndex];
				nextValue = new Date(parseISO(nextValue));
				nextValue = addDays(nextValue, i);
				nextValue = format(nextValue, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

				let prevValue = selectedLocation.satellites[formerSatelliteKey].dates[formerIndex];
				prevValue = new Date(parseISO(prevValue));
				prevValue = subDays(prevValue, i);
				prevValue = format(prevValue, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

				if (nextValue) {
					let newIndex = selectedLocation.satellites[satelliteKey].dates.indexOf(nextValue);
					if (newIndex > 0) {
						exitSearch = true;
						newValue = dayjs(format(new Date(parseISO(nextValue)), Constants.DATE_FORMATS.SHORT_DATE_FORMAT));
					}
				}
				if (prevValue) {
					let newIndex = selectedLocation.satellites[satelliteKey].dates.indexOf(prevValue);
					if (newIndex > 0) {
						exitSearch = true;
						newValue = dayjs(format(new Date(parseISO(prevValue)), Constants.DATE_FORMATS.SHORT_DATE_FORMAT));
					}
				}
			}

			const continueCallBack = async () => {
				await setSatteliteView(data);

				continueToSuitableDate(data, newValue);

				setConfirmModalState(null);
			};
			let originDate = format(selectedDate['$d'], Constants.DATE_FORMATS.MONTH_NAME_LONG_YEAR_FORMAT);
			let destinationDate = format(newValue['$d'], Constants.DATE_FORMATS.MONTH_NAME_LONG_YEAR_FORMAT);
			// selectedDate['$d']
			setConfirmModalState({
				open: true,
				title: 'Missing data',
				message: `We do not have data for ${text} on ${originDate}.`,
				message2: 'What would wou like to do?',
				confirmButtonText: `Move to next available date -${destinationDate}`,
				confirmButtonCb: continueCallBack,
				cancelButtonCb: () => {
					setConfirmModalState(null);
				},
			});
		}
	};

	let dateFormat = user && user.units === 'metric' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

	const ActionsComponent = (
		<div className="actions-container">
			<MaterialAutoComplete blurOnClose={true} options={locationsOptions} className={'select-location-container'} isTablet={isTablet} handleChange={handleChange} />

			<LocalizationProvider dateAdapter={AdapterDayjs}>
				{!isTablet ? (
					<DatePicker
						minDate={minDate}
						maxDate={maxDate}
						disabled={isDisabled || (!images && !images?.length > 0)}
						shouldDisableDate={shouldDisableDate}
						slots={{
							day: RenderDate,
						}}
						value={selectedDate ? selectedDate : defaultDate}
						onMonthChange={onMonthChange}
						onChange={onDayChange}
						defaultCalendarMonth={selectedMonth}
						className="topbar-date-picker"
						format={dateFormat}
					/>
				) : (
					<MobileDatePicker
						minDate={minDate}
						maxDate={maxDate}
						disabled={isDisabled || (!images && !images?.length > 0)}
						shouldDisableDate={shouldDisableDate}
						disableCloseOnSelect={false}
						slots={{
							day: RenderDate,
						}}
						slotProps={{
							textField: {
								InputProps: {
									endAdornment: (
										<InputAdornment position="end">
											<EventIcon color={isDisabled || (!images && !images?.length > 0) ? 'disabled' : 'inherit'} />
										</InputAdornment>
									),
								},
							},
						}}
						onAccept={(data) => onDayChange(data)}
						value={selectedDate ? selectedDate : defaultDate}
						onMonthChange={onMonthChange}
						defaultCalendarMonth={selectedMonth}
						className="topbar-date-picker"
						format={dateFormat}
					/>
				)}
			</LocalizationProvider>

			<SatteliteSelect options={satteliteOptions} menuItemClassName={'sattelite-menu-item'} fontStyleClassName="satellite-text-style" satteLiteView={satteLiteView} setSatteliteView={satelliteViewChanged} isMenuDisabled={!selectedLocation.name} />
		</div>
	);

	return (
		<div className="main-top-bar">
			<div className="logo-container">
				<img src="/logo2.png" className="logo" alt="BlueGreen Water Technologies" />
			</div>
			<div className="border-new-ui"></div>

			{ActionsComponent}

			<div className="icons-container">
				{!isDisabled && userCanEdit && <MaterialTooltipIconButton iconButtonStyle={{ padding: '0' }} className="icons-sizes" title={settingsTooltip} isDisabled={isDisabled && !userCanEdit} edge={'end'} Icon={SettingsTwoToneIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} onClick={editLocation} />}

				{allowExport && !isDisabled && (
					<MaterialTooltipIconButton iconButtonStyle={{ padding: '0' }} className="icons-sizes" title={exportTooltip} isDisabled={!allowExport || isDisabled} edge={'end'} Icon={GetAppOutlinedIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} onClick={() => setModalOpened(true)} />
				)}

				{/* <MaterialTooltipIconButton title={feedbackTooltip} edge={'end'} Icon={FeedbackOutlinedIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} /> */}

				{/* <div className="icons-divider"></div> */}

				<MaterialTooltipIconButton style iconButtonStyle={{ padding: '0' }} className="icons-sizes" title={logoutTooltip} edge={'end'} Icon={LogoutIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} onClick={logoutAction} spanStyle={{ right: '16px', position: 'absolute' }} />
			</div>
		</div>
	);
}

export default TopBar;
