/**
 * Description of LegendControl.js
 *
 * This module is a mapbox control that allows the user to view the legend for the map.
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */ 

import {Constants} from '../components/Constants';
import _ from 'lodash';

class LegendControl {
	constructor(bgi = false, bloom = false) {
        this.bgi = bgi;
        this.bloom = bloom;
    }

	createLegendColours(legend) {
        const _this = this;

		let response = [];
		Constants.LEGENDS[legend].filter((_item) => _item.display === true || _item.display === undefined).forEach((item) => {
			if (item.colour !== 'transparent') {
                _this.tr = document.createElement('tr');
                _this.tr.className = 'legendRow';
                _this.tr.key = `legend_${legend}_${item.id}`;
                _this.td = document.createElement('td');
                _this.td.className = `legendCell${_.includes(['Clouds'], item.id) ? ' dark' : ''}`;
                _this.td.style.backgroundColor = item.colour;
                _this.td.style.color = item.inverse ? 'black' : 'white';
                _this.td.innerHTML = item.label;
                _this.td.title = item.title ? item.title : `${item.label}`;
                _this.tr.appendChild(_this.td);
                response.push(_this.tr);
			}
		});

		return response;
	}

	onAdd(map) {
		const _this = this;
		_this.map = map;
		_this.legendControl = document.createElement('div');
		_this.legendControl.className = 'legends';
		_this.bgiLegend = document.createElement('div');
		_this.bgiLegend.id = 'bgiLegend';
		_this.bgiLegend.className = 'legend hidden';
        _this.table = document.createElement('table');
        _this.tbody = document.createElement('tbody');
        _this.tr = document.createElement('tr');
        _this.tr.className = 'legendRow';
        _this.td = document.createElement('td');
        _this.td.className = 'legendHeader';
        _this.td.innerHTML = 'BlueGreen Index (BGI)';
        _this.td.style.backgroundColor = 'rgba(0, 0, 0, var(--bg-opacity))';
        _this.tr.appendChild(_this.td);
        _this.tbody.appendChild(_this.tr);
        this.createLegendColours('bgi').forEach((_tr) => _this.tbody.appendChild(_tr));
        _this.table.appendChild(_this.tbody);
        _this.bgiLegend.appendChild(_this.table);

		_this.bloomLegend = document.createElement('div');
		_this.bloomLegend.id = 'bloomLegend';
		_this.bloomLegend.className = 'legend hidden';
        _this.table = document.createElement('table');
        _this.tbody = document.createElement('tbody');
        _this.tr = document.createElement('tr');
        _this.tr.className = 'legendRow';
        _this.td = document.createElement('td');
        _this.td.className = 'legendHeader';
        _this.td.innerHTML = 'Bloom Level (Chlorophyll A)';
        _this.td.style.backgroundColor = 'rgba(0, 0, 0, var(--bg-opacity))';
        _this.tr.appendChild(_this.td);
        _this.tbody.appendChild(_this.tr);
        this.createLegendColours('bloom').forEach((_tr) => _this.tbody.appendChild(_tr));
        _this.table.appendChild(_this.tbody);
        _this.bloomLegend.appendChild(_this.table);

		_this.legendControl.appendChild(_this.bgiLegend);
		_this.legendControl.appendChild(_this.bloomLegend);

		_this.container = document.createElement('div');
        _this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-legendControl';
		_this.container.appendChild(_this.legendControl);
		return _this.container;
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container);
		this.map = undefined;
	}

	hide(legend) {
        
        if(legend && this[legend]) {
		    this[legend].classList.add('hidden');
        }
        else {
            this.legendControl.classList.add('hidden');
        }
	}

	show(legend) {
        if (legend) {
            if (Array.from(this.legendControl.classList).includes('hidden')) {
                this.legendControl.classList.remove('hidden');
            }

            if (this[legend]) {
                this[legend].classList.remove('hidden');
            } else {
                console.log('Legend not found: ', legend);
            }
		} else {
			this.legendControl.classList.remove('hidden');
		}
	}
}

export default LegendControl;
