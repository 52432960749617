/**
 * Description of Trends.js
 *
 * This module contains Trend Charts rendering code for LakeGuard View
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import React, { useState } from 'react';
import _ from 'lodash';
import { createContainer, VictoryChart, VictoryAxis, VictoryLabel, VictoryStack, VictoryLegend, VictoryTooltip, VictoryArea, Area, VictoryBar, LineSegment } from 'victory';
import { Constants } from './Constants';
import { format as formatDate, differenceInCalendarYears } from 'date-fns';
import { isBlank, log } from './Services';
import { victoryLGVTheme } from './victoryLGVTheme';
import SHARE_ICON from '../css/images/share.png';
import CHEVRON_DOWN_ICON from '../css/images/chevron_down.png';
import { confirmAlert } from 'react-confirm-alert';
import { adjustForDataGaps, getValues } from '../services/trendsServices';
import ViewWearther from './trends/components/bottomSection/viewWeather/ViewWearther';
import ViewTrend from './trends/components/bottomSection/viewTrend/ViewTrends';

function Trends(props) {
	let context = props.data;
	let heightFactor = window.matchMedia('(min-height: 913px) and (max-height: 1079px)').matches ? 0.9 : 1;

	let trendData = [];
	let [trendType, setTrendType] = useState('BloomLevel');
	let [weatherType, setWeatherType] = useState('Visibility');

	let [dateRanges, setDateRangesState] = useState(Constants.TREND_DATE_RANGES);

	let [dateRange, setDateRangeState] = useState(Constants.TREND_DATE_RANGES.find((_dateRange) => _dateRange.id === 'PastWeek'));

	let aoiRange = Constants.TREND_AOI_RANGES.find((_aoiRange) => _aoiRange.id === 'AllAOI');

	let period = {
		current: [],
		previous: [],
		averages: {},
		max: {},
		min: {},
	};

	const labelComponent = (lines, oversize) => {
		let width = oversize ? 300 : 200,
			dx = 0,
			style = [{ fontSize: 16, fontWeight: 'bold' }];

		for (let line = 0; line <= lines; line++) {
			style.push({
				fontSize: line === 1 ? 28 : line === 0 ? 18 : 16,
				fontWeight: line === 1 || line === 0 ? 'bold' : 'normal',
			});
		}

		return (
			<VictoryTooltip
				constrainToVisibleArea
				horizontal
				cornerRadius={4}
				flyoutStyle={{
					fill: 'rgb(229, 229, 230)',
				}}
				flyoutWidth={width}
				flyoutHeight={(lines + 1) * 25 + 10}
				floyoutPadding={5}
				labelComponent={<VictoryLabel style={style} dx={dx} />}
			/>
		);
	};

	const victoryArea = (dataSeries, from, data, adjustment = 0) => {
		let response = <></>,
			labels,
			_labelComponent;

		if (weatherType === 'UV' && context.doc.querySelector('.UVWarning')) {
			context.doc.querySelector('.UVWarning').classList.add('hidden');
		}

		if (from === 'trends') {
			labels = (datum) => [`${dateRange.tag}${formatDate(datum.date, dateRange.legendFormat)}`, Constants.TREND_TYPES.find((_trendType) => _trendType.key === trendType).label, `${datum[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`];
			_labelComponent = labelComponent(2, !isBlank(dateRange.tag));

			response = data.map((_data, _index) => (
				<VictoryArea
					style={{
						data: { stroke: '#058AB5', fill: 'rgba(5, 128, 181, 0.2)' },
						parent: {
							border: '1px solid #ccc',
							height: '190px',
							width: '600px',
						},
					}}
					key={'a' + _index}
					data={_data}
					x="date"
					y={dataSeries}
					dataComponent={
						<Area
							events={{
								onClick: (event) => handleClick(event),
							}}
						/>
					}
					interpolation="catmullRom"
					labels={({ datum }) => labels(datum)}
					labelComponent={_labelComponent}
				/>
			));
		} else {
			labels = (datum) => {
				let response = [`${dateRange.tag}${formatDate(datum.date, dateRange.legendFormat)}`, weatherType, `${(datum[dataSeries] - adjustment).toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`];

				if (dataSeries !== 'UV' && dataSeries !== 'Precipitation') {
					response.push(`Min: ${dataSeries === 'Visibility' ? (100 - datum.Weather.cloudcover_max).toFixed(1) : datum.Weather[dataSeries === 'Temp' ? 'temperature_2m_min' : 'windspeed_10m_min'].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`);
					response.push(`Max: ${dataSeries === 'Visibility' ? (100 - datum.Weather.cloudcover_min).toFixed(1) : datum.Weather[dataSeries === 'Temp' ? 'temperature_2m_max' : 'windspeed_10m_max'].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`);
				}

				return response;
			};

			_labelComponent = labelComponent(4, !isBlank(dateRange.tag));

			if (dataSeries === 'UV') {
				data = data.filter((_datum) => _datum.UV !== undefined && !isNaN(_datum.UV));

				if (weatherType === 'UV' && data.length !== period.current.length) {
					context.doc.querySelector('.UVWarning').classList.remove('hidden');
				}
			}

			response = (
				<VictoryArea
					style={{
						data: { stroke: '#058AB5', fill: 'rgba(5, 128, 181, 0.2)' },
						parent: {
							border: '1px solid #ccc',
							height: '190px',
							width: '600px',
						},
					}}
					data={data}
					x="date"
					y={dataSeries}
					interpolation="catmullRom"
					labels={({ datum }) => labels(datum)}
					labelComponent={_labelComponent}
				/>
			);
		}

		return response;
	};

	const victoryAreaWithEstimates = (dataSeries, from, estimate, data) => {
		let trends = from === 'trends',
			response = <></>,
			dataComponent = !trends ? (
				<></>
			) : (
				<Area
					events={{
						onClick: (event) => handleClick(event),
					}}
				/>
			),
			dataStyle = trends
				? {
						data: {
							stroke: estimate ? 'transparent' : '#058AB5',
							fill: estimate ? 'rgba(137, 172, 187, 0.2)' : 'rgba(5, 128, 181, 0.2)',
						},
						parent: {
							border: '1px solid #ccc',
							height: `${190 * heightFactor}px`,
							width: '600px',
						},
				  }
				: {
						data: { stroke: '#058AB5', fill: 'rgba(5, 128, 181, 0.2)' },
						parent: {
							border: '1px solid #ccc',
							height: `${190 * heightFactor}px`,
							width: '600px',
						},
				  },
			labels = (datum) => {
				return trends
					? [`${dateRange.tag}${formatDate(datum.date, dateRange.legendFormat)}`, dateRange.label, `${datum[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`, `${datum.estimated ? 'Estimated' : ''}`]
					: [
							`${dateRange.tag}${formatDate(datum.date, dateRange.legendFormat)}`,
							weatherType,
							`${datum[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`,
							`Min: ${period.min[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`,
							`Max: ${period.max[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`,
					  ];
			},
			_labelComponent = labelComponent(trends ? 2 : 5, !isBlank(dateRange.tag));

		if (dataSeries === 'UV' && data.length !== period.current.length && context.doc.querySelector('.UVWarning')) {
			context.doc.querySelector('.UVWarning').classList.remove('hidden');
		} else if (dataSeries !== 'UV' && context.doc.querySelector('.UVWarning')) {
			context.doc.querySelector('.UVWarning').classList.add('hidden');
		}

		if (_.isArray(data[0])) {
			response = data[0].map((_data, _index) => <VictoryArea style={dataStyle} key={'a' + _index} data={_data} x="date" y={dataSeries} dataComponent={dataComponent} interpolation="catmullRom" labels={({ datum }) => labels(datum)} labelComponent={_labelComponent} />);
		} else {
			response = <VictoryArea style={dataStyle} data={data} x="date" y={dataSeries} dataComponent={dataComponent} interpolation="catmullRom" labels={({ datum }) => labels(datum)} labelComponent={_labelComponent} />;
		}

		return response;
	};

	const victoryBar = (waterComponent, data) => {
		let response = <></>,
			actualWaterComponent = waterComponent === 'BloomLevel' ? 'BloomSpread' : waterComponent,
			legendItem = Constants.LEGENDS.bgi.find((_legend) => _legend.id === waterComponent), // this must be waterComponent, not actualWaterComponent to get the correct legendItem
			labels = (datum, label) => {
				return [`${dateRange.tag}${formatDate(datum.date ? datum.date : datum.x ? datum.x : new Date(), dateRange.legendFormat)}`, label, `${(datum[actualWaterComponent] ? datum[actualWaterComponent] : datum.y ? datum.y : 0).toFixed(1)}%`, `${datum.estimated ? 'Estimated' : ''}`];
			},
			_labelComponent = labelComponent(2, !isBlank(dateRange.tag)),
			dataStyle = {
				data: {
					stroke: 'rgba(0, 0, 0, 0.25)',
					strokeWidth: 1,
					fill: legendItem.colour,
				},
				parent: {
					border: '1px solid #ccc',
					height: `${190 * heightFactor}px`,
					width: '600px',
				},
			},
			y = actualWaterComponent;

		response = data.map((_data, _index) => {
			return <VictoryBar style={dataStyle} key={'b' + _index} data={_data} x="date" y={y} labels={({ datum }) => labels(datum, legendItem.label)} labelComponent={_labelComponent} />;
		});

		return response;
	};

	const viewWaterComponents = () => {
		const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');

		let containerComponent = (
				<VictoryZoomVoronoiContainer
					responsive={true}
					zoomDimension="x"
					//zoomDomain={   state.zoomDomain} // needed for brush interaction
					//onZoomDomainChange={   handleZoom.bind(this)} // needed for brush interaction
				/>
			),
			data = [adjustForDataGaps('waterComponents', true, period.current)],
			response = [
				<React.Fragment key="WaterComponents">
					<VictoryChart theme={victoryLGVTheme} preserveAspectRatio="none" width={600} height={190 * heightFactor} domainPadding={10} padding={{ left: 70, right: 25, top: 50, bottom: 0 }} containerComponent={containerComponent}>
						<VictoryAxis
							dependentAxis
							fixLabelOverlap={true}
							tickFormat={(datum) => `${datum}%`}
							style={{
								axis: { stroke: { fill: 'none' } },
								tickLabels: { fill: 'black', fontSize: 15 },
							}}
						/>
						<VictoryAxis
							orientation="bottom"
							fixLabelOverlap={true}
							tickValues={data[0].map((_datum) => _datum.date)}
							tickFormat={(datum) => `${dateRange.format.includes('Q') ? 'Q' : ''}${formatDate(new Date(datum), dateRange.format)}`}
							style={{
								ticks: { stroke: 'black', size: 5 },
								tickLabels: { fill: 'black', fontSize: 15 },
							}}
							gridComponent={<LineSegment style={{ stroke: 'none' }} />}
						/>
						<VictoryLegend
							orientation="horizontal"
							title="Water Components (BGI)"
							titleComponent={<VictoryLabel style={{ fontSize: 18, fontWeight: 'bold' }} />}
							labelComponent={<VictoryLabel style={{ fontSize: 16 }} />}
							gutter={40}
							data={[
								{
									name: 'Clouds',
									symbol: {
										stroke: '#000',
										strokeWidth: 1,
										fill: Constants.LEGENDS.bgi.find((_legend) => _legend.label === 'Clouds').colour,
										type: 'diamond',
									},
									x: 50,
								},
								{
									name: 'SS',
									symbol: {
										fill: Constants.LEGENDS.bgi.find((_legend) => _legend.id === 'SS').colour,
										type: 'diamond',
									},
								},
								{
									name: 'SML',
									symbol: {
										fill: Constants.LEGENDS.bgi.find((_legend) => _legend.id === 'SML').colour,
										type: 'diamond',
									},
								},
								{
									name: 'CDOM',
									symbol: {
										fill: Constants.LEGENDS.bgi.find((_legend) => _legend.id === 'CDOM').colour,
										type: 'diamond',
									},
								},
								{
									name: 'Bloom Level',
									symbol: {
										fill: Constants.LEGENDS.bgi.find((_legend) => _legend.id === 'BloomLevel').colour,
										type: 'diamond',
									},
								},
							]}
						/>
						<VictoryStack colorScale={Constants.WATER_COMPONENTS.map((_waterComponent) => _waterComponent.colour)}>
							{victoryBar('Clouds', data)}
							{victoryBar('SS', data)}
							{victoryBar('SML', data)}
							{victoryBar('CDOM', data)}
							{victoryBar('BloomLevel', data)}
						</VictoryStack>
					</VictoryChart>
				</React.Fragment>,
			];

		return response;
	};

	const handleClick = (event) => {
		// console.log("handleClick", event);
	};

	const selectTrendType = (event, value) => {
		setTrendType(value || trendType);
	};

	const selectWeatherType = (event) => {
		setWeatherType(event.currentTarget.dataset.weathertype);
	};

	const setDateRange = (event) => {
		let value = dateRanges.find((_dateRange) => _dateRange.id === event.target.value);
		setDateRangeState(value);
	};

	const renderTrendType = (trendTypeProp) => {
		return (
			<td className={`trendType`}>
				<div className={`trendTile ${trendType} ${trendType === trendTypeProp ? ' activeTrend' : ''}`} data-trendtype={trendType} onClick={(e) => selectTrendType(e, trendTypeProp)}>
					<div className="heading">{Constants.TREND_TYPES.find((_trendType) => _trendType.key === trendTypeProp).label}</div>
					<span className="trendValue">{period.averages[trendTypeProp]}</span>
					<span className="trendAnnotation">{period.averages[`${trendTypeProp}Annotation`]}</span>
					<br />
					<span className="trendDelta">{period.averages[`${trendTypeProp}Delta`]}</span>
				</div>
			</td>
		);
	};

	try {
		if (context.hasTrendData) {
			trendData = context.trendData;

			if (dateRanges.length === Constants.TREND_DATE_RANGES.length) {
				let additionalRanges = [];
				let thisYear = new Date().getFullYear();

				for (let yearIndex = 1; yearIndex <= differenceInCalendarYears(trendData[trendData.length - 1].date, trendData[0].date); yearIndex++) {
					additionalRanges.push({
						id: (thisYear - yearIndex).toString(),
						label: (thisYear - yearIndex).toString(),
						format: Constants.DATE_FORMATS.SHORT_MONTH_NAME_FORMAT,
						legendFormat: Constants.DATE_FORMATS.SHORT_MONTH_NAME_FORMAT,
						title: 'By Day',
						tag: '',
					});
				}

				if (trendData[0].date.getFullYear() === trendData[trendData.length - 1].date.getFullYear()) {
					dateRanges.splice(-1);
				}

				let newValue = [...dateRanges, ...additionalRanges];
				if (newValue.length !== dateRanges.length) {
					setDateRangesState(newValue);
				}
			}

			let ranges = {
				dateRange: (
					<select className="trendDateRangeSelector" onChange={setDateRange} value={dateRange.id}>
						{dateRanges.map((_dateRange) => (
							<option key={'c' + Math.floor(Math.random() * 99999999999) + _dateRange.id} value={_dateRange.id}>
								{`${Constants.CLOCK} ${_dateRange.label}`}
							</option>
						))}
					</select>
				),
				allAOI: (
					<select
						className="trendAOISelector"
						// onChange={   setAOI}
						defaultValue={aoiRange.label}>
						{Constants.TREND_AOI_RANGES.map((_aoiRange) => (
							<option key={'d' + _aoiRange.id} value={_aoiRange.id}>
								{`${Constants.CROSSHAIR} ${_aoiRange.label}`}
							</option>
						))}
					</select>
				),
			};

			// period = getValues();
			period = getValues(trendData, dateRange, context);

			let BloomLevel = renderTrendType('BloomLevel'),
				BloomSpread = renderTrendType('BloomSpread'),
				Carbon = renderTrendType('Carbon');

			const title = 'Water Body Trends';
			const uvButtonLabel = 'UV';
			const tempButtonLabel = 'Temp';
			const windButtonLabel = 'Wind';
			const precipitationButtonLabel = 'Precipitation';
			const visibilityButtonLabel = 'Visibility';
			const uvWarning = weatherType === 'UV' && dateRanges.findIndex((_range) => _range.id === dateRange.id) > 2 ? '' : 'hidden';
			const viewTrendProps = {
				adjustForDataGaps: adjustForDataGaps,
				trendType: trendType,
				current: period.current,
				dateRange: dateRange,
				victoryArea: victoryArea,
				victoryAreaWithEstimates: victoryAreaWithEstimates,
			};

			return (
				<React.Fragment key={'trends' + new Date()}>
					<div className="trends">
						<div className="trendsHeading">
							<span className="trendTitle left">{title}</span>
							<span className="right controls">
								<span className="share" onClick={context.close}>
									<img src={SHARE_ICON} alt="Share" title="Share" />
								</span>
								<span className="close" onClick={context.close}>
									<img src={CHEVRON_DOWN_ICON} alt="Close" title="Close" />
								</span>
							</span>
						</div>
						<div className="trendsRanges">
							<span>
								{ranges.dateRange}
								{Constants.v5 && ranges.allAOI}
							</span>
						</div>
						<hr />
						<table className="trendsTable">
							<tbody>
								<tr>
									{BloomLevel}
									{BloomSpread}
									{context.user.groups.includes('carbon') && Carbon}
								</tr>
							</tbody>
						</table>
						<div className="trendsChart">
							<ViewTrend {...viewTrendProps} />
						</div>
						<hr />
						<div className="trendsWeather">
							<span className={`UV ${weatherType === 'UV' ? ' activeWeather' : ''}`} data-weathertype="UV" onClick={selectWeatherType}>
								{uvButtonLabel}
							</span>
							<span className={`Temp ${weatherType === 'Temp' ? ' activeWeather' : ''}`} data-weathertype="Temp" onClick={selectWeatherType}>
								{tempButtonLabel}
							</span>
							<span className={`Wind ${weatherType === 'Wind' ? ' activeWeather' : ''}`} data-weathertype="Wind" onClick={selectWeatherType}>
								{windButtonLabel}
							</span>
							<span className={`Wind ${weatherType === 'Precipitation' ? ' activeWeather' : ''}`} data-weathertype="Precipitation" onClick={selectWeatherType}>
								{precipitationButtonLabel}
							</span>
							<span className={`Visibility ${weatherType === 'Visibility' ? ' activeWeather' : ''}`} data-weathertype="Visibility" onClick={selectWeatherType}>
								{visibilityButtonLabel}
							</span>
							<span className={`UVWarning _red1 right ${uvWarning}`}>{`*UV Index not available prior to ${formatDate(trendData.find((_trendData) => _trendData.Weather.uv_index !== 'N/A').date, Constants.DATE_FORMATS.DATE_FORMAT)}`}</span>
							<div className="weatherChart">
								{' '}
								<ViewWearther period={period} dateRange={dateRange} weatherType={weatherType} height={190 * heightFactor} victoryArea={victoryArea} />{' '}
							</div>
						</div>
						<hr />
						<div className="trendsWaterComponents">{viewWaterComponents()}</div>
					</div>
				</React.Fragment>
			);
		}
	} catch (error) {
		if (dateRange.id !== 'PastWeek') {
			setDateRangeState(Constants.TREND_DATE_RANGES.find((_dateRange) => _dateRange.id === 'PastWeek'));
			confirmAlert({
				title: `Data Was Not Available`,
				message: `Sorry, we don't seem to have the data for this request.`,
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: [
					{
						label: 'OK',
						onClick: _.noop(),
					},
				],
			});
			return;
		} else {
			log('trends:: render: ', error.message, JSON.stringify(error.stack));
			return <></>;
		}
	}
}

export { Trends };
