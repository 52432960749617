/**
 * Description of LocationEditor.js
 *
 * This module contains image rendering code for LakeGuard View
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import React from "react";
import {
  Button,
  TextareaAutosize,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { endOfYear, add as addDays, sub as subDays } from "date-fns";
import { Constants } from "./Constants";
import { isBlank } from "./Services";

function LocationEditor(props) {
  const context = props.data;

  const creatingLabel = 'Creating ...';
  const usersLabel = 'Users';
  const accessRestrictionLabel = 'Access Restrictions';
  const endLabel= 'End:';
  const startLabel= 'Start:';
  const descriptionLabel = 'Description:';
  const nameLabel= 'Name:';
  const locationDescriptionPlaceholder= 'Location description (optional)';
  const cancelButtonLabel= 'Cancel';
  const saveButtonLabel = 'Save';

  const viewLocationEditor = () => {
    let polygonArea =
        context.location.polygonArea *
        Constants.UNITS[context.units].area.factor
          ? Math.ceil(
              context.location.polygonArea *
                Constants.UNITS[context.units].area.factor
            ).toFixed(2)
          : undefined,
      buttons = [
        <Button
          className="btnSaveLocation"
          variant="contained"
          color="primary"
          size="medium"
          id={`btnYes_${context.locationIndex}`}
          onClick={context.saveLocation}
        >
          {saveButtonLabel}
        </Button>,
        <Button
          className="btnCancelLocation"
          variant="contained"
          color="primary"
          size="medium"
          id={`btnNo_${context.locationIndex}`}
          onClick={context.cancelSaveLocation}
        >
          {cancelButtonLabel}
        </Button>,
      ],
      buttonBar = (
        <React.Fragment>
          {buttons[0]}
          {buttons[1]}
        </React.Fragment>
      ),
      [startDate, endDate] = [
        context.location.dateRange[0].start_date
          ? new Date(context.location.dateRange[0].start_date)
          : subDays(new Date(), { years: 7 }),
        context.location.dateRange[0].end_date
          ? new Date(context.location.dateRange[0].end_date)
          : endOfYear(new Date()),
      ],
      setLocationName = (event) => context.setLocationName(event.target.value),
      setDescription = (event) => context.setDescription(event.target.value),
      setStartDate = (date) => context.setDateRange([date, null]),
      setEndDate = (date) => context.setDateRange([null, date]),
      setAccess = (event) => context.setAccess(event.target.value),
      setMembers = (event, newValue) => context.setMembers(newValue),
      autocompleteOptions =
        !context.location?.members || context.location.members.length === 0
          ? [...["Everyone"], ...context.users]
          : context.users;

    return polygonArea ? (
      <>
        <h2 className="heading">
          {context.mode === "Add" ? "Create Location" : "Modify Location"}
          {/* in new ui should be edit location not modify location */}
        </h2>
        {!context.userIsAdmin &&
        (polygonArea > Constants.MAX_POLYGON_AREA ||
          polygonArea < Constants.MIN_POLYGON_AREA) ? (
          <>
            <div>
              This AOI is{" "}
              {Number(
                context.location.polygonArea *
                  Constants.UNITS[context.units].area.factor
              ).toFixed(2)}{" "}
              location.polygonArea * Constants.UNITS[context.units].area.label,
              which is{" "}
              {polygonArea > Constants.MAX_POLYGON_AREA ? "larger" : "smaller"}{" "}
              than the{" "}
              {polygonArea > Constants.MAX_POLYGON_AREA ? "maximum" : "minimum"}{" "}
              area of{" "}
              {
                Constants[
                  polygonArea > Constants.MAX_POLYGON_AREA
                    ? "MAX_POLYGON_AREA"
                    : "MIN_POLYGON_AREA"
                ]
              }{" "}
              location.polygonArea * Constants.UNITS[context.units].area.label.
              &nbsp;&nbsp;Please redefine your AOI.
              <br />
              <br />
            </div>
            {buttons[1]}
          </>
        ) : (
          <>
            <table className="tblSettings">
              <tbody>
                <tr>
                  <td>
                    <label>{nameLabel}</label>
                  </td>
                  <td colSpan={2}>
                    <input
                      type="text"
                      id={`polygonName_${context.locationIndex}`}
                      className="name"
                      defaultValue={context.location.name}
                      onBlur={setLocationName}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{descriptionLabel}</label>
                  </td>
                  <td colSpan={2}>
                    <TextareaAutosize
                      id={`description_${context.locationIndex}`}
                      name="description"
                      className="description"
                      defaultValue={context.location.description}
                      minRows={5}
                      maxRows={5}
                      placeholder={locationDescriptionPlaceholder}
                      onBlur={setDescription}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{startLabel}</label>
                  </td>
                  <td colSpan={2} className="locationStartDate">
                    <DatePicker
                      calendarClassName="startDate"
                      minDate={new Date(2016, 1, 1)}
                      maxDate={addDays(new Date(), { years: 1 })}
                      selected={startDate}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      onChange={setStartDate}
                      onBlur={setStartDate}
                      showPopperArrow={false}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{endLabel}</label>
                  </td>
                  <td colSpan={2} className="locationEndDate">
                    <DatePicker
                      calendarClassName="endDate"
                      minDate={new Date(2016, 1, 1)}
                      maxDate={addDays(new Date(), { years: 1 })}
                      selected={endDate}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      onChange={setEndDate}
                      onBlur={setEndDate}
                      showPopperArrow={false}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h2 className="heading">{accessRestrictionLabel}</h2>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <RadioGroup
                      aria-label="access-restrictions"
                      name="access"
                      row
                      onChange={setAccess}
                      defaultValue={
                        context.location.publicAccess ||
                        !isBlank(context.location.members)
                          ? "publicAccess"
                          : "private"
                      }
                    >
                      <FormControlLabel
                        value="private"
                        control={<Radio />}
                        label="Private"
                      />
                      <FormControlLabel
                        value="publicAccess"
                        control={<Radio />}
                        label="Public/Restricted"
                      />
                    </RadioGroup>
                  </td>
                </tr>
                <tr
                  className={`publicAccess${
                    !context.location.publicAccess ? " hidden" : ""
                  }`}
                >
                  <td>{usersLabel}</td>
                  <td>
                    <Autocomplete
                      id="users"
                      multiple
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={autocompleteOptions}
                      getOptionLabel={(option) => option}
                      value={
                        isBlank(context.location.members)
                          ? ["Everyone"]
                          : context.location.members
                      }
                      onChange={setMembers}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={usersLabel}
                          placeholder={usersLabel}
                        />
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            {buttonBar}
            <span
              className="loading hidden"
              id={`loading_${context.locationIndex}`}
            >
              {creatingLabel}
            </span>
          </>
        )}
      </>
    ) : (
      []
    );
  };

  if (
    (context.isAddLocation && context.mode === Constants.MODES.Add) ||
    (context.isModifyLocation && context.mode === Constants.MODES.Update)
  ) {
    return (
      <React.Fragment key="locationManager">
        <div className="locationEditor">{viewLocationEditor()}</div>
      </React.Fragment>
    );
  }

  return <></>;
}

export { LocationEditor };
