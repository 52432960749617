import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './index.css';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
// import { useOnClickOutside } from '../../../../components/layerManager/LayerManagerComponent';

export default function MaterialAutoComplete(props) {
	const { className, options, handleChange, blurOnClose = false, isTablet } = props;

	const [open, setOpen] = React.useState(true);
	const inputRef = React.useRef(null);

	const renderInput = (params) => {
		return (
			<TextField
				{...params}
				// InputProps={{
				// 	...params.InputProps,
				// }}
				inputRef={inputRef}
				placeholder="Search a Location"
				onClick={() => setOpen(true)}
				// onBlur={() => setOpen(false)}
			/>
		);
	};

	const onClose = (event) => {
		if (event && event.target && blurOnClose) {
			event.target.blur && event.target.blur();
		}
		if (isTablet) {
			setTimeout(() => {
				inputRef.current.blur();
			}, 0);
		}
	};

	React.useEffect(() => {
		function handleClickOutside(event) {
			event && event.stopPropagation();
			let listClassName = '#country-select-demo-listbox';
			let listRef = document.querySelector(listClassName);
			if (inputRef.current && inputRef.current.contains(event.target)) {
			} else if (listRef && listRef.contains(event.target)) {
			} else {
				if (open) {
					onClose(event);
					setOpen(false);
				}
			}
		}

		let eventName = isTablet ? 'touchend' : 'mousedown';
		// Bind the event listener
		document.addEventListener(eventName, handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener(eventName, handleClickOutside);
		};
	});

	return (
		<div className="main-select-container">
			<PlaceOutlinedIcon style={{ height: '16px', width: '16px', marginRight: '5px', position: 'absolute', color: 'rgba(0,0,0,0.75)', marginLeft: '5px' }} />

			<Autocomplete
				id="country-select-demo"
				sx={{ width: 200, maxWidth: 200, maxLines: 1 }}
				options={options}
				autoHighlight
				getOptionLabel={(option) => option.label || ''}
				isOptionEqualToValue={(option, value) => {
					return option.locationsId === value.locationsId;
				}}
				open={open}
				disableClearable
				className={className}
				onClose={isTablet ? () => {} : onClose}
				onChange={(event, value) => {
					handleChange(value);
					setOpen(false);
				}}
				blurOnSelect={true}
				renderOption={(props, option) => (
					<Box component="li" {...props} style={{ padding: '7px 15px 7px 8px', height: '31px' }}>
						<span key={option.name + ' ' + option.country} style={{ display: 'flex' }}>
							<label style={{ width: '150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: 'Inter', fontWeight: '500', fontSize: '14px', color: 'rgba(0,0,0,0.75)' }}>{option.label}</label>
							<span>{option.publicAccess ? <PublicOutlinedIcon style={{ width: '16px', height: '16px', color: 'rgba(0,0,0,0.5)' }} /> : <PersonOutlineOutlinedIcon style={{ width: '16px', height: '16px', color: 'rgba(0,0,0,0.5)' }} />}</span>
						</span>
					</Box>
				)}
				renderInput={renderInput}
			/>
		</div>
	);
}
