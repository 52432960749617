import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import logoImg from '../css/images/logo_colour.png';
import { Card, Logo, Form, Input, Button, Error, Status } from '../components/AuthForm';
import { useAuth } from '../context/auth';
import md5 from 'md5';
import { Constants } from '../components/Constants';
import _ from 'lodash';
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent';
import { log, fetch } from '../components/Services';

function Login(props) {
	const token = localStorage.getItem('tokens'),
		[isLoggedIn, setLoggedIn] = useState(!_.isEmpty(token)),
		[isError, setIsError] = useState(false),
		[errorMessage, setErrorMessage] = useState(''),
		[isStatus, setIsStatus] = useState(false),
		[statusMessage, setStatusMessage] = useState(''),
		[userName, setUserName] = useState(''),
		[password, setPassword] = useState(''),
		{ setAuthTokens } = useAuth(),
		referer = props?.location?.state?.referer || '/',
		postAuthenticateRequest = async () => {
			if (getCookieConsentValue() === 'true') {
				log('Login::postAuthenticateRequest: Authenticating user ...');
				setStatusMessage('Authenticating...');
				setIsStatus(true);

				await fetch({
					url: `authenticate`,
					data: {
						username: userName,
						password: md5(password),
					},
				})
					.then(async (user) => {
						user = user || {};

						if (user && user.authenticated) {
							setAuthTokens(user.token);
							localStorage.setItem('usersId', user.usersId);
							setLoggedIn(true);
						} else {
							if (user.reason) {
								setErrorMessage(user.reason);
							} else {
								setErrorMessage('LakeGuard® View is currently offline');
							}

							log(`Login::postAuthenticateRequest: Authenticating error: ${user.reason || user.Error || 'unknown error'}`);
							setIsError(true);
						}
					})
					.catch((error) => {
						console.log(error);
						setErrorMessage('LakeGuard® View is currently offline');
						setIsError(true);
					});
			} else {
				setErrorMessage('Please accept cookies.');
				setIsError(true);
			}
		},
		handleKeyDown = async (event) => {
			if (event.keyCode === 13) {
				postAuthenticateRequest();
			}
		};

	if (isLoggedIn) {
		return <Redirect to={referer} />;
	} else {
		window.history.replaceState({}, '', '/');

		return (
			<>
				<Card>
					<Logo src={logoImg} style={{ marginTop: '30px', transform: 'scale(2, 2)' }} />
					<h3 style={{ color: 'white', fontSize: '40px', fontWeight: 'bold', marginTop: '20px', marginBottom: '20px', lineHeight: '40px' }}>Lake Guard® View</h3>
					<Form autoComplete="off">
						<Input
							type="username"
							value={userName}
							onChange={(event) => {
								setUserName(event.target.value);
								setErrorMessage('');
								setIsError(false);
								setIsStatus(false);
							}}
							placeholder="username"
						/>
						<Input
							type="password"
							value={password}
							onChange={(event) => {
								setPassword(event.target.value);
								setErrorMessage('');
								setIsError(false);
								setIsStatus(false);
							}}
							onKeyDown={handleKeyDown}
							placeholder="password"
							autoComplete="off"
						/>
						<Button
							className="popupButton btnLogin"
							onClick={(event) => {
								setIsStatus(false);
								setErrorMessage('');
								setIsError(false);
								postAuthenticateRequest();
							}}>
							Sign In
						</Button>
						{isError ? (
							<Error style={{ padding: '5px', color: 'white', textAlign: 'center' }}>{errorMessage}</Error>
						) : isStatus ? (
							<Status style={{ padding: '5px', color: 'white', textAlign: 'center' }}>{statusMessage}</Status>
						) : (
							<Status style={{ padding: '5px', color: 'white', backgroundColor: 'black', textAlign: 'center' }}></Status>
						)}
						<p style={{ color: 'white', textAlign: 'center', fontSize: 'larger', lineHeight: '30px' }}>
							For registration information, please visit{' '}
							<a style={{ color: 'teal', fontSize: 'larger', fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer" href="https://bluegreenwatertech.com/data-science#LGV">
								bluegreenwatertech.com
							</a>
						</p>
						<span style={{ color: 'white', textAlign: 'center', lineHeight: '25px' }}>
							&copy; 2023 BlueGreen Water Technologies Ltd.
							<br />
							{Constants.DEV ? <span className="_red">DEV</span> : Constants.STAGING ? <span className="_red">STAGING</span> : Constants.TEST ? <span className="_red">TEST</span> : Constants.EXPERT ? <span className="_green">EXPERT</span> : Constants.DEMO ? <span className="_cyan">DEMO</span> : null}
							{Constants.API} Version {localStorage.getItem('version') || '1.0.0'}
						</span>
					</Form>
				</Card>
				<CookieConsent
					enableDeclineButton
					onDecline={() => {
						resetCookieConsentValue();
						window.location.reload();
					}}>
					LakeGuard&reg; View requires the use of cookies to retain specific data required for its operation. For further information, please view our <a href="tos.html">Terms and Conditions</a>
				</CookieConsent>
			</>
		);
	}
}

export default Login;
