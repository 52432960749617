/**
 * Description of LayerManager.js
 *
 * This module contains Layer Manager rendering code for LakeGuard View
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import React, { useState } from 'react';
import { Constants } from './Constants';
import { isAuthorized } from '../services/common';

function LayerManager(props) {
	const context = props.data;

	const [state, setState] = useState({
		selectedImage: context.imagery.status === 'planetOnly' ? 'Planet' : 'Sentinel-3', // view default S3 images
		planetImage: undefined,
	});
	
	if (state.selectedImage === 'Planet' && !props.selectedLayers.includes('planet') && !context.imagery.urls.planet_url  ) {
		setState({ selectedImage: 'Sentinel-3', planetImage: undefined });
	}

	const selectedViewClassName = 'trueColourSelected';

	const sentinel3 = 'Sentinel 3';
	const close = 'Close';
	const planetLabel = 'Planet';
	const raylightLabel = 'Rayleigh';
	const onlyAOILabel = 'S3 AOI';
	// const copernicusLabel = 'Copernicus';
	const downloadLabel = 'Downloads';
	const bloomLabel = 'Bloom';
	const twoDimesnsionsLabel = '2D';
	const threeDimesnsionsLabel = '3D';
	const temperatureLabel = 'Temp:';
	const windLabel = 'wind:';

	const viewImageryDetails = () => {
		let imageryDetails = [],
			doc = document,
			date,
			weather,
			trueColour,
			bgi,
			bloom,
			downloads,
			thumbnails = context.images[context.imageryDate].thumbnails,
			planetTime = state.selectedImage === 'Planet' ? context.imagery.planetTimestamp : undefined,
			dateString = context.images[context.imageryDate].date,
			timeString =
				state.selectedImage === 'Sentinel-3'
					? context.images[context.imageryDate].time
					: new Date(`${context.images[context.imageryDate].date.toString().split('T')[0]} ${context.imagery.planetTimestamp ? `${planetTime.substr(0, 2)}:${planetTime.substr(2, 2)}:${planetTime.substr(4, 2)}` : '00:00:00'}`).toLocaleTimeString();

		trueColour = (
			<tr>
				<td className={`tdImage centre tdImage buttonImagery_trueColour ${props.selectedLayers.includes('trueColour') ? 'selected' : ''} `} id={`buttonImagery_trueColour_${context.imagery.imageryId}`} layer-mode="trueColour" imagery-id={context.imagery.imageryId}>
					<table>
						<tbody>
							<tr>
								<td className="imageryDetailsLayerModeContainer">
									<div className="imageryDetailsLayerMode">
										{context.imagery.status !== 'planetOnly' && (
										<span
											className={`trueColourHeading trueColourSentinel3 ${context.imagery.urls.planet_url ? ' left' : ''} ${state.selectedImage === 'Sentinel-3' ? selectedViewClassName : ''}  `}
											onClick={() => {
												doc.querySelector('.imageryDetailsImage').innerHTML = <img className="imageryDetailsImage buttonImageryDetails_trueColour " src={thumbnails.trueColour.thumbnail} alt="Sentinel-3" width={`${Constants.THUMBNAIL_WIDTH}px`} />;
												setState({ selectedImage: 'Sentinel-3' });
											}}
											style={{opacity: state.selectedImage === 'Sentinel-3' ? '1' : '0.4'}}
											>
											{sentinel3}
										</span>
										)}
										<span className="right controls">
											<span className="close" onClick={() => context.close()} title={close}>
												{Constants.DOWN}
											</span>
										</span>
										{context.imagery.urls.planet_url && context.imagery.urls.planet_url !== Constants.BLANK && (
											<span
												className={` 'trueColourHeading trueColourPlanet right' ${state.selectedImage === planetLabel ? selectedViewClassName : ''} `}
												onClick={() => {
													setState({ selectedImage: planetLabel });
												}}
												style={{opacity: state.selectedImage === 'Planet' ? '1' : '0.4'}}
												>
												{planetLabel}
											</span>
										)}
									</div>
								</td>
							</tr>
							<tr>
								<td
									className={`tdImage centre tdImage buttonImagery_${state.selectedImage === 'Planet' ? 'planet' : 'trueColour'}`}
									id={`buttonImagery_${state.selectedImage === 'Planet' ? 'planet' : 'trueColour'}_${context.imagery.imageryId}`}
									onClick={
										state.selectedImage === 'Sentinel-3'
											? thumbnails.trueColour.thumbnail !== Constants.DEFAULT_IMAGE
												? async () => {
														await context.showTrueColour(context.imagery.imageryId);
												  }
												: null
											: context.showPlanet
									}
									layer-mode={state.selectedImage === 'Planet' ? 'planet' : 'trueColour'}
									layer-source={context.imagery.urls.planet_url}
									imagery-id={context.imagery.imageryId}>
									{state.selectedImage === 'Sentinel-3' && context.imagery.status !== 'planetOnly' ? (
										<img className={`imageryDetailsImage buttonImageryDetails_trueColour `} src={thumbnails.trueColour.thumbnail} alt={sentinel3} width={`${Constants.THUMBNAIL_WIDTH}px`} />
									) : (
										<img className={`imageryDetailsImage buttonImageryDetails_planet ${props.selectedLayers.includes('planet') ? 'selected' : ''}`} src={context.imagery.urls.planet_url} alt="Planet" width={`${Constants.THUMBNAIL_WIDTH}px`} />
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		);

		date = (
			<tr>
				<td className="imageryDetailsDate">
					{dateString}&nbsp;{timeString}
				</td>
			</tr>
		);

		if (thumbnails.weather && context.imagery.status !== 'planetOnly') {
			weather = (
				<tr>
					<td className="imageryDetailsWeather">
						{temperatureLabel}&nbsp;
						<span style={{ fontWeight: 'bold' }}>
							{thumbnails.weather.temperature_2m} {Constants.UNITS[context.units].Temp.label}
						</span>
						&nbsp;{windLabel}&nbsp;
						<span style={{ fontWeight: 'bold' }}>
							{thumbnails.weather.windspeed_10m} {Constants.UNITS[context.units].Wind.label} {thumbnails.weather.winddirection_10m}
						</span>
					</td>
				</tr>
			);
		}

		bgi = context.imagery.status !== 'planetOnly' && (
			<tr>
				<td
					className={`tdImage centre tdImage buttonImagery_bgi ${props.selectedLayers.includes('bgi') && thumbnails.bgi.thumbnail !== Constants.DEFAULT_IMAGE ? 'selected' : ''}`}
					id={`buttonImagery_bgi_${context.imagery.imageryId}`}
					onClick={
						thumbnails.bgi.thumbnail !== Constants.DEFAULT_IMAGE
							? async () => {
									await context.showBGI(context.imagery.imageryId);
							  }
							: null
					}
					layer-mode="bgi"
					imagery-id={context.imagery.imageryId}>
					<p className="imageryDetailsLayerMode">BlueGreen Index (BGI)</p>
					<img className="imageryDetailsImage buttonImageryDetails_bgi" src={thumbnails.bgi.thumbnail} alt="BGI" width={`${Constants.THUMBNAIL_WIDTH}px`} />
				</td>
			</tr>
		);

		bloom = context.imagery.status !== 'planetOnly' && (
			<tr>
				<td
					className={`tdImage centre tdImage centre buttonImagery_bloom  ${props.selectedLayers.includes('bloom') && thumbnails.bloom.thumbnail !== Constants.BLANK ? 'selected' : ''}`}
					id={`buttonImagery_bloom_${context.imagery.imageryId}`}
					onClick={
						thumbnails.bloom.thumbnail !== Constants.BLANK
							? async () => {
									await context.showBloom(context.imagery.imageryId);
							  }
							: null
					}
					layer-mode="bloom"
					imagery-id={context.imagery.imageryId}>
					{!thumbnails.bloom.caption ? (
						<>
							<p className="imageryDetailsLayerMode">{bloomLabel}<br />
								Average {(context.imagery.chartData.Bloom * Constants.UNITS[context.user.units].BloomLevel.factor).toFixed(Constants.UNITS[context.user.units].BloomLevel.decimals)} {Constants.UNITS[context.user.units].BloomLevel.label}
							</p>
							<img className="imageryDetailsImage buttonImageryDetails_bloom" src={thumbnails.bloom.thumbnail} alt={bloomLabel} width={`${Constants.THUMBNAIL_WIDTH}px`} />
							<br />
							<span>
								<button className={`popupButton buttonPitch hidden`} id={`buttonImagery_bloom_pitch`} pitch={context.is3D ? '3D' : '2D'} layer-mode="bloom" imagery-id={context.imagery.imageryId} onClick={context.togglePitch}>
									{context.is3D ? twoDimesnsionsLabel : threeDimesnsionsLabel}
								</button>
								&nbsp;
								<button className={`popupButton buttonRotate hidden`} id={`buttonImagery_bloom_rotate`} layer-mode="bloom" imagery-id={context.imagery.imageryId} onClick={context.rotate}>
									360°
								</button>
							</span>
						</>
					) : (
						<div className="imageryDetailsImage buttonImageryDetails_bloom noImage">
							<p className="imageryDetailsLayerMode">{bloomLabel}</p>
							<p className="imageryDetailsUnavailable">{thumbnails.bloom.caption}</p>
						</div>
					)}
				</td>
			</tr>
		);

		downloads =
			isAuthorized(context.user, ['expert']) && Object.keys(context.imagery.urls).length > 0 ? (
				<>
					<tr>
						<td className="centerRow bold">
							<p>{downloadLabel}</p>
						</td>
					</tr>
					<tr>
						<td className="centerRow">
							<span>
								{/* <button className="popupButton" onClick={() => window.open(context.imagery.link)}>
									{copernicusLabel}
								</button> */}
								&nbsp;
								{context.imagery.urls.Subsetted300m_zip_url && (
									<>
										<button className="popupButton" onClick={() => window.open(context.imagery.urls.Subsetted300m_zip_url)}>
											{onlyAOILabel}
										</button>
										&nbsp;
									</>
								)}
								{context.imagery.urls.Rayleigh300m_zip_url && (
									<button className="popupButton" onClick={() => window.open(context.imagery.urls.Rayleigh300m_zip_url)}>
										{raylightLabel}
									</button>
								)}
								{context.imagery.urls.planet_zip && (
									<>
										&nbsp;
										<button key={`planet`} className="popupButton" onClick={() => window.open(context.imagery.urls.planet_zip)}>
											{planetLabel}
										</button>
									</>
								)}
							</span>
						</td>
					</tr>
				</>
			) : (
				<></>
			);

		imageryDetails = (
			<table className="tblImageryDetails">
				<tbody>
					{trueColour}
					{timeString ? date : <></>}
					{state.selectedImage === 'Sentinel-3' ? weather : <></>}
					{state.selectedImage === 'Sentinel-3' ? bgi : <></>}
					{state.selectedImage === 'Sentinel-3' ? bloom : <></>}
					{downloads}
				</tbody>
			</table>
		);

		return imageryDetails;
	};

	if (context.hasImageryDetails && context.imagery && context.images[context.imageryDate]) {
		return (
			<React.Fragment key="imageryDetails">
				<div className="imageryDetails" key={`imageryDetails_${context.imagery.date}`}>
					{viewImageryDetails()}
				</div>
			</React.Fragment>
		);
	}

	return <></>;
}

export default LayerManager;
