import React from 'react';
import './index.css';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { VictoryLabel, VictoryTooltip, VictoryArea, Area } from 'victory';
import _ from 'lodash';
import { isBlank } from '../../../Services';

import { format as formatDate } from 'date-fns';
import { Constants } from '../../../Constants';
import { adjustForDataGaps, getWeatherArrayType } from '../../../../services/trendsServices';
import ViewWearther from './viewWeather/ViewWearther';
import ViewTrend from './viewTrend/ViewTrends';

export default function BottomSection({ dateRange, current, trendType, period, weatherType, context, setWeatherType, trendData, dateRanges, isTablet }) {
	let heightFactor = window.matchMedia('(min-height: 913px) and (max-height: 1079px)').matches ? 0.9 : 1;

	const uvWarning = weatherType === 'UV' && dateRanges.findIndex((_range) => _range.id === dateRange.id) > 2 ? '' : 'hidden';

	const handleClick = (event) => {};

	const victoryArea = (dataSeries, from, data, adjustment = 0) => {
		let response = <></>,
			labels,
			_labelComponent;

		if (from === 'trends') {
			labels = (datum) => [`${dateRange.tag}${formatDate(datum.date, dateRange.legendFormat)}`, Constants.TREND_TYPES.find((_trendType) => _trendType.key === trendType).label, `${datum[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`];
			_labelComponent = labelComponent(2, !isBlank(dateRange.tag));

			response = data.map((_data, _index) => (
				<VictoryArea
					style={{
						data: { stroke: '#058AB5', fill: 'rgba(5, 128, 181, 0.2)' },
						parent: {
							border: '1px solid #ccc',
							height: '190px',
							width: '600px',
						},
					}}
					key={'a' + _index}
					data={_data}
					x="date"
					y={dataSeries}
					dataComponent={
						<Area
							events={{
								onClick: (event) => handleClick(event),
							}}
						/>
					}
					interpolation="catmullRom"
					labels={({ datum }) => labels(datum)}
					labelComponent={_labelComponent}
				/>
			));
		} else {
			labels = (datum) => {
				let response = [`${dateRange.tag}${formatDate(datum.date, dateRange.legendFormat)}`, weatherType, `${(datum[dataSeries] - adjustment).toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`];

				if (dataSeries !== 'UV' && dataSeries !== 'Precipitation') {
					response.push(`Min: ${dataSeries === 'Visibility' ? (100 - datum.Weather.cloudcover_max).toFixed(1) : datum.Weather[dataSeries === 'Temp' ? 'temperature_2m_min' : 'windspeed_10m_min'].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`);
					response.push(`Max: ${dataSeries === 'Visibility' ? (100 - datum.Weather.cloudcover_min).toFixed(1) : datum.Weather[dataSeries === 'Temp' ? 'temperature_2m_max' : 'windspeed_10m_max'].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`);
				}

				return response;
			};
			_labelComponent = labelComponent(4, !isBlank(dateRange.tag));

			if (dataSeries === 'UV') {
				data = data.filter((_datum) => _datum.UV !== undefined && !isNaN(_datum.UV));
			}

			response = (
				<VictoryArea
					style={{
						data: { stroke: '#058AB5', fill: 'rgba(5, 128, 181, 0.2)' },
						parent: {
							border: '1px solid #ccc',
							height: '190px',
							width: '600px',
						},
					}}
					data={data}
					x="date"
					y={dataSeries}
					interpolation="catmullRom"
					labels={({ datum }) => labels(datum)}
					labelComponent={_labelComponent}
				/>
			);
		}

		return response;
	};

	const victoryAreaWithEstimates = (dataSeries, from, estimate, data) => {
		let trends = from === 'trends',
			response = <></>,
			dataComponent = !trends ? (
				<></>
			) : (
				<Area
					events={{
						onClick: (event) => handleClick(event),
					}}
				/>
			),
			dataStyle = trends
				? {
						data: {
							stroke: dateRange.id === 'Yearly' ? 'rgb(5, 138, 181)' : estimate ? 'transparent' : '#058AB5',
							fill: estimate ? (dateRange.id === 'Yearly' ? 'rgba(5, 128, 181, 0.2)' : 'rgba(137, 172, 187, 0.2)') : 'rgba(5, 128, 181, 0.2)',
						},
						parent: {
							border: '1px solid #ccc',
							height: `${190 * heightFactor}px`,
							width: '600px',
						},
				  }
				: {
						data: { stroke: '#058AB5', fill: 'rgba(5, 128, 181, 0.2)' },
						parent: {
							border: '1px solid #ccc',
							height: `${190 * heightFactor}px`,
							width: '600px',
						},
				  },
			labels = (datum) => {
				return trends
					? [`${dateRange.tag}${formatDate(datum.date, dateRange.legendFormat)}`, dateRange.label, `${datum[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`, `${datum.estimated ? 'Estimated' : ''}`]
					: [
							`${dateRange.tag}${formatDate(datum.date, dateRange.legendFormat)}`,
							weatherType,
							`${datum[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`,
							`Min: ${period.min[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`,
							`Max: ${period.max[dataSeries].toFixed(1)} ${period.averages[`${dataSeries}Annotation`]}`,
					  ];
			},
			_labelComponent = labelComponent(trends ? 2 : 5, !isBlank(dateRange.tag));

		if (_.isArray(data[0])) {
			response = data[0].map((_data, _index) => <VictoryArea style={dataStyle} key={'a' + _index} data={_data} x="date" y={dataSeries} dataComponent={dataComponent} interpolation="catmullRom" labels={({ datum }) => labels(datum)} labelComponent={_labelComponent} />);
		} else {
			response = <VictoryArea style={dataStyle} data={data} x="date" y={dataSeries} dataComponent={dataComponent} interpolation="catmullRom" labels={({ datum }) => labels(datum)} labelComponent={_labelComponent} />;
		}

		return response;
	};

	const labelComponent = (lines, oversize) => {
		let width = oversize ? 300 : 200,
			dx = 0,
			style = [{ fontSize: 16, fontWeight: 'bold' }];

		for (let line = 0; line <= lines; line++) {
			style.push({
				fontSize: line === 1 ? 28 : line === 0 ? 18 : 16,
				fontWeight: line === 1 || line === 0 ? 'bold' : 'normal',
			});
		}

		return (
			<VictoryTooltip
				constrainToVisibleArea
				// horizontal
				cornerRadius={4}
				flyoutStyle={{
					fill: 'rgba(229, 229, 230, 0.2)',
				}}
				flyoutWidth={width}
				flyoutHeight={(lines + 1) * 25 + 10}
				floyoutPadding={5}
				labelComponent={<VictoryLabel style={style} dx={dx} />}
			/>
		);
	};

	const viewTrendProps = {
		adjustForDataGaps: adjustForDataGaps,
		trendType: trendType,
		current: current,
		dateRange: dateRange,
		victoryArea: victoryArea,
		victoryAreaWithEstimates: victoryAreaWithEstimates,
	};

	const firstGraph = (
		<>
			{' '}
			<ViewTrend {...viewTrendProps} />{' '}
		</>
	);

	const [selectedWeatherType, setSelectedWeatherType] = React.useState('UV');

	const selectWeatherType = (event, selectedWeather) => {
		if (event && event.target && event.target.textContent && event.target.textContent === selectedWeatherType) return;

		setSelectedWeatherType(selectedWeather);
		setWeatherType(selectedWeather);
	};

	const weatherTypeArray = getWeatherArrayType();

	const toggleButtonsGroup = (
		<div className="second-container">
			<ToggleButtonGroup className="toggle-buttons" value={selectedWeatherType} exclusive onChange={selectWeatherType} aria-label="">
				{weatherTypeArray.map((el, index) => (
					<ToggleButton key={index} value={el.value} aria-label={el.ariaLabel} style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '500', textTransform: 'initial', color: 'rgba(0,0,0,0.75)' }}>
						{el.title}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</div>
	);

	const viewWeatherProps = {
		period,
		dateRange,
		weatherType,
		height: 200,
		victoryArea,
	};

	let date = trendData.find((_trendData) => _trendData.Weather.uv_index !== 'N/A');
	if (date) {
		date = date.date;
	} else {
		date = new Date();
	}

	const secondGraph = (
		<>
			<div className="weatherChart">
				<div style={{ display: 'flex', height: '10px', width: '230px', paddingTop: '10px' }} className={`UVWarning _red1 right ${uvWarning}`}>{`*UV Index not available prior to ${formatDate(date, Constants.DATE_FORMATS.DATE_FORMAT)}`}</div>
				<ViewWearther {...viewWeatherProps} />
			</div>
		</>
	);
	return (
		<div className={isTablet ? 'first-chart tablet' : 'first-chart'}>
			{firstGraph}
			{toggleButtonsGroup}
			{secondGraph}
		</div>
	);
}
