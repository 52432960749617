import React from 'react';
import { createContainer, VictoryChart, VictoryAxis, LineSegment } from 'victory';
import { format as formatDate } from 'date-fns';
import { victoryLGVTheme } from '../../../../victoryLGVTheme';
import _ from 'lodash';

export default function ViewTrend({ adjustForDataGaps, trendType, current, dateRange, victoryArea, victoryAreaWithEstimates }) {
	const viewTrend = () => {
		const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi'),
			splitArrayByContiguousDates = (data) => {
				if (data.length === 0) {
					return [];
				}

				const result = [];
				let currentArray = [data[0]];

				for (let i = 1; i < data.length; i++) {
					const currentDate = new Date(data[i].date);
					const previousDate = new Date(data[i - 1].date);

					// Calculate the difference in days between current and previous date
					const timeDiff = Math.abs(currentDate - previousDate);
					const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

					// If the difference is 1 day, dates are contiguous; add to the current array
					if (daysDiff === 1) {
						currentArray.push(data[i]);
					} else {
						// Dates are not contiguous; push the current array and start a new one
						result.push(currentArray);
						currentArray = [data[i]];
					}
				}

				// Push the last array
				result.push(currentArray);

				return [result];
			},
			actualData = adjustForDataGaps(trendType, false, current),
			estimatedData = adjustForDataGaps(trendType, true, current),
			containerComponent = <VictoryZoomVoronoiContainer responsive={true} zoomDimension="x" />,
			victoryAxis = (
				<VictoryAxis
					orientation="bottom"
					fixLabelOverlap={true}
					tickValues={estimatedData.map((_datum) => _datum.date)}
					tickFormat={(datum) => `${dateRange.format.includes('Q') ? 'Q' : ''}${formatDate(new Date(datum), dateRange.format)}`}
					style={{
						ticks: { stroke: 'black', size: 5 },
						tickLabels: { fill: 'black', fontSize: 15 },
					}}
					gridComponent={<LineSegment style={{ stroke: 'none' }} />}
				/>
			),
			victoryDependentAxis = (
				<VictoryAxis
					dependentAxis
					tickFormat={(datum) => `${datum}`}
					fixLabelOverlap={true}
					style={{
						axis: { stroke: { fill: 'none' } },
						tickLabels: { fill: 'black', fontSize: 15 },
					}}
				/>
			),
			victoryChart = (
				<VictoryChart theme={victoryLGVTheme} preserveAspectRatio="none" width={600} height={200} domainPadding={10} padding={{ left: 70, right: 25, top: 30, bottom: 30 }} containerComponent={containerComponent}>
					{victoryDependentAxis}
					{victoryAxis}
					{!_.includes(['BloomLevel', 'Carbon'], trendType) && victoryArea(trendType, 'trends', [actualData])}
					{trendType === 'BloomLevel' && victoryAreaWithEstimates(trendType, 'trends', true, estimatedData)}
					{trendType === 'BloomLevel' && victoryAreaWithEstimates(trendType, 'trends', false, splitArrayByContiguousDates(_.reject(estimatedData, (_data) => _data.estimated)))}
				</VictoryChart>
			);
		return (
			<React.Fragment key={'trends'}>
				{victoryChart}
				{/* <div className="trendsDateBy">{dateRange.title}</div> */}
			</React.Fragment>
		);
	};
	return viewTrend();
}
