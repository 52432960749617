import React from 'react';
import './index.css';
import { bgiColors, bgiColorsNumbers } from '../../shared/UI/constants/colorsPalette';

export default function LegendComponent({ isTablet }) {
	return (
		<div className={isTablet ? 'legend-container tablet' : 'legend-container'}>
			{bgiColors.map((el, index) => (
				<div className="legend-row" key={index}>
					<div className="color-block" style={el.style}></div>
					<span className="color-title">{el.title}</span>
				</div>
			))}

			<div className="legend-divider" />

			{bgiColorsNumbers.map((el, index) => (
				<div className="legend-row" key={index + el.title}>
					<div className="color-block" style={el.style}></div>
					<span className="color-title">{el.title}</span>
				</div>
			))}
		</div>
	);
}
