import React, { useRef, useState } from 'react';
import './index.css';
import BodySection from './components/bodySection/BodySection';
import BottomSection from './components/bottomSection/BottomSection';
import MainSection from './components/mainSection/MainSection';
import { MenuItem, Select, Typography } from '@mui/material';
import { Constants } from '../Constants';
import { getValues } from '../../services/trendsServices';
import { differenceInCalendarYears, format } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LakeInfo from './components/lakeInfo/LakeInfo';
// import { getSatelliteParamName } from '../../services/mapboxServices';

export default function MainTrends(props) {
	const trendsTitle = 'Water body trends';
	const defaultTrendType = 'BloomLevel';
	const defaultWeatherType = 'UV';
	const { selectedDate, isTablet, location, user, collapseTrends } = props.data;

	let context = props.data;
	let trendData = [];
	let [dateRange, setDateRangeState] = useState(Constants.TREND_DATE_RANGES.find((_dateRange) => _dateRange.id === 'PastWeek'));

	let [trendType, setTrendType] = useState(defaultTrendType);

	let [weatherType, setWeatherType] = useState(defaultWeatherType);

	let [dateRanges, setDateRangesState] = useState(Constants.TREND_DATE_RANGES);

	const [headerData, setHeaderData] = useState(null);

	if (context.trendData) {
		trendData = context.trendData;
	}

	let period = getValues(trendData, dateRange, context);

	const previousDate = useRef(null);

	if (trendData && trendData.length > 0 && selectedDate && previousDate.current !== selectedDate['$d']) {
		let formattedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		let index = trendData.findIndex((data) => {
			let formatGivenDate = format(data.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
			return formatGivenDate === formattedDate;
		});

		previousDate.current = selectedDate['$d'];
		setHeaderData(trendData[index]); // header data should get its data from imagery[satelliteView].trendData

		// let imagery;
		// let satelliteKey = getSatelliteParamName(satteLiteView)
		// if(images && Object.keys(images) && Object.keys(images).length > 0 ){
		// 	let formatSelectedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		// 	imagery = images[formatSelectedDate].imagery;
		// 	if(imagery && imagery[satelliteKey] && imagery[satelliteKey].trendData ){
		// 		setHeaderData(imagery[satelliteKey].trendData);// header data should get its data from imagery[satelliteView].trendData
		// 	}
		// }
	}

	function toFixed(x, key) {
		return Number.parseFloat(x).toFixed(Constants.UNITS[context.user.units][key].decimals);
	}

	const bloomSpreadAverage = period.averages['BloomSpread'];
	const bloomLevelAverage = period.averages['BloomLevel'];
	const bloomSpreadAnnotation = period.averages[`BloomSpreadAnnotation`];
	const bloomLevelAnnotation = period.averages[`BloomLevelAnnotation`];
	let bloomLevelDelta = period.averages[`BloomLevelDelta`];
	let bloomLevelDirection = '';
	let bloomSpreadDelta = period.averages[`BloomSpreadDelta`];
	let bloomSpreadDirection = '';

	const mainSectionProps = {
		tempratureMin: headerData ? headerData.Weather?.temperature_2m_min : '',
		tempratureMax: headerData ? headerData.Weather.temperature_2m_max : '',
		windSpeed: headerData ? headerData.Weather.windspeed_10m : '',
		bloomLevel: headerData ? toFixed(headerData.BloomLevel, 'BloomLevel') : '',
		bloomSpread: headerData ? toFixed(headerData.BloomSpread, 'BloomSpread') : '',
		selectedDate: selectedDate,
		user: context.user,
		bloomLevelAnnotation: bloomLevelAnnotation,
		wc: headerData && headerData.Weather ? headerData.Weather.weathercode : 0,
		windDirection: headerData && headerData.Weather ? headerData && headerData.Weather.winddirection_10m : '',
		precip: headerData && headerData.Weather ? headerData && headerData.Weather.precipitation : '',
		isTablet: isTablet,
	};

	if (bloomLevelDelta && bloomLevelDelta.includes(Constants.UP)) {
		bloomLevelDelta = bloomLevelDelta.replace(Constants.UP, '');
		bloomLevelDirection = 'up';
	}
	if (bloomLevelDelta && bloomLevelDelta.includes(Constants.DOWN)) {
		bloomLevelDelta = bloomLevelDelta.replace(Constants.DOWN, '');
		bloomLevelDirection = 'down';
	}
	if (bloomSpreadDelta && bloomSpreadDelta.includes(Constants.UP)) {
		bloomSpreadDelta = bloomSpreadDelta.replace(Constants.UP, '');
		bloomSpreadDirection = 'up';
	}
	if (bloomSpreadDelta && bloomSpreadDelta.includes(Constants.DOWN)) {
		bloomSpreadDelta = bloomSpreadDelta.replace(Constants.DOWN, '');
		bloomSpreadDirection = 'down';
	}

	const bodyProps = { bloomSpreadAverage, bloomLevelAverage, bloomSpreadAnnotation, bloomLevelAnnotation, bloomLevelDelta, bloomSpreadDelta, bloomLevelDirection, bloomSpreadDirection, setTrendType, setDateRangeState };

	const bottomProps = {
		dateRange,
		current: period.current,
		trendType: trendType,
		period,
		weatherType,
		context,
		setWeatherType,
		trendData: trendData,
		dateRanges: dateRanges,
		isTablet: isTablet,
	};

	const setDateRange = (event) => {
		let value = dateRanges.find((_dateRange) => _dateRange.id === event.target.value);
		setDateRangeState(value);
	};

	if (dateRanges.length === Constants.TREND_DATE_RANGES.length) {
		let additionalRanges = [];
		let thisYear = new Date().getFullYear();

		for (let yearIndex = 1; yearIndex <= differenceInCalendarYears(trendData[trendData.length - 1].date, trendData[0].date); yearIndex++) {
			additionalRanges.push({
				id: (thisYear - yearIndex).toString(),
				label: (thisYear - yearIndex).toString(),
				format: Constants.DATE_FORMATS.MONTH_NAME_FORMAT,
				legendFormat: Constants.DATE_FORMATS.MONTH_NAME_FORMAT,
				title: 'By Week',
				tag: 'Week of ',
			});
		}

		if (trendData[0].date.getFullYear() === trendData[trendData.length - 1].date.getFullYear()) {
			dateRanges.splice(-1);
		}

		let newValue = [...dateRanges, ...additionalRanges];
		if (newValue.length !== dateRanges.length) {
			setDateRangesState(newValue);
		}
	}

	const lakeInfoProps = {
		location: location,
		user: user,
	};

	let mainContainerClassName = 'trends-container';
	if (isTablet) {
		mainContainerClassName = mainContainerClassName + ' tablet';
	}
	if (collapseTrends) {
		mainContainerClassName = mainContainerClassName + ' collapsed';
	}

	return (
		<div className={mainContainerClassName}>
			<MainSection {...mainSectionProps} />

			<Typography className="trends-title" style={{ color: '#000000', opacity: '0.75', letterSpacing: '-0.28px' }}>
				{trendsTitle}
			</Typography>

			<div style={{ padding: '4px 8px 4px 28px', height: '41px', paddingBottom: '16px', display: 'flex' }}>
				<Select value={dateRange.id} className="select-period" onChange={setDateRange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
					{dateRanges.map((_dateRange) => (
						<MenuItem key={'c' + Math.floor(Math.random() * 99999999999) + _dateRange.id} value={_dateRange.id}>
							<AccessTimeIcon style={{ height: '16px', width: '16px' }} fontSize="small" /> <span style={{ marginLeft: '6px' }}> {_dateRange.label}</span>
						</MenuItem>
					))}
				</Select>
			</div>

			<BodySection {...bodyProps} />
			<BottomSection {...bottomProps} />
			<LakeInfo {...lakeInfoProps} />
		</div>
	);
}
