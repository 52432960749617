/**
 * Description of BrandingControl.js
 *
 * This module is a mapbox control displays branding on the map.
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

class BrandingControl {

	onAdd(map) {
		const _this = this;
		_this.map = map;
		_this.brandingControl = document.createElement('div');
		_this.brandingControl.className = 'branding';
		_this.brandingControl.id = 'branding';
		_this.brand = document.createElement('div');
		_this.brand.className = 'brand';
		_this.brand.id = 'brand';
		_this.brandingControl.append(_this.brand);
		_this.container = document.createElement('div');
		_this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-brandingControl';
		_this.container.appendChild(_this.brandingControl);
		return _this.container;
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container);
		this.map = undefined;
	}
}

export default BrandingControl;
