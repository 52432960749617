/**
 * Description of Navigator.js
 *
 * This module contains image rendering code for LakeGuard View
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import React, { useRef, useState } from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isBefore, startOfYear, endOfYear, endOfMonth, add as addDays } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-vars.less';
import 'swiper/swiper.scss';
import { Constants } from './Constants';
import { isBlank } from './Services';
import { isAuthorized } from '../services/common';

function Navigator(props) {
	const context = props.data;

	const [selected, setSelected] = useState('trueColour');
	const [selectedDate, setSelectedDate] = useState('');
	const [exportClicked, setExportClicked] = useState(false);

	const getIsDisabledClass = () => {
		if (exportClicked) {
			datesClassNameRef.current = '';
			return 'disabled';
		} else {
			datesClassNameRef.current = 'hidden';
			return '';
		}
	};

	const datesClassNameRef = useRef('hidden');
	//context.analysis

	const startLabel = 'Start :';
	const endLabel = 'End:';
	const startActionLabel = 'Start';
	const stopActionLabel = 'Stop';
	const timeSeriesActionLabel = 'Time Series';
	const trendsActionLabel = 'Trends';
	const exportActionLabel = 'Export';
	const modifyActionLabel = 'Modify';
	const CanelActionLabel = 'Cancel';
	const deleteActionLabel = 'Delete';
	const closeActionLabel = 'Close';
	const monthDisplayLabel = 'Month Displayed:';
	const bloomActionButton = 'Bloom';
	const bgIndexActionButton = 'BG Index';
	const naturalActionButton = 'Natural';

	const imageryBlockClick = async (value, hasAnalysis) => {
		if (!context.layerLoading) {
			if (selectedDate === value) {
				await context.closeLayerManager();
				await setSelectedDate('');
			} else {
				await setSelectedDate(value);
				if (hasAnalysis || context.images[value].thumbnails[selected].caption === 'Planet Available') {
					await context.showLayerManager(true, value);
				}
			}
		} else {
			console.log('imageryBlockClick: layerLoading, click ignored');
		}
	};

	const viewNavigator = () => {
		let imageryCols = [];

		const analysesButtonClicked = async (event, value) => {
			await context.setAnalysis(event, value);
			setSelected(value);
		};

		const createImageryContent = () => {
				for (let imageDate of Object.keys(context.images).reverse()) {
					if (context.images[imageDate].thumbnails) {
						let hasAnalysis = !context.images[imageDate].thumbnails[selected].caption,
							hasPlanet = context.location.imagery.find((_imagery) => _imagery.date === imageDate && _imagery?.urls?.planet_url) || false;

						imageryCols = [
							...imageryCols,
							<SwiperSlide key={`swiper_${imageDate}_${selected}}`}>
								<span className={`imageryBlock imageryBlock_${imageDate} centre`} id={`imageryBlock_${imageDate}_${selected}`} key={`imageryBlock_${imageDate}_${selected}}`} onClick={async () => await imageryBlockClick(imageDate, hasAnalysis)}>
									{hasAnalysis ? (
										<img
											className={`image imageButton imageButton${_.upperFirst(selected)} buttonImageryNavigator_${selected} ${imageDate === selectedDate ? 'selected' : ''}`}
											src={context.images[imageDate].thumbnails[selected].thumbnail}
											width={`${Constants.THUMBNAIL_WIDTH}px`}
											alt={`${imageDate}`}
											id={`img_${imageDate}`}
											key={`img_${imageDate}`}
											analysis={selected}
											imagery-id={context.images[imageDate].imagery.imageryId}
										/>
									) : (
										<div style={{ marginBottom: '5px', cursor: `${context.images[imageDate].thumbnails[selected].caption === 'Planet Available' ? 'pointer' : 'disabled'}` }} className={`image imageButton imageButton${_.startCase(selected)}`}>
											<span
												style={{
													display: 'table-cell',
													height: `${Constants.THUMBNAIL_HEIGHT}px`,
													width: `${Constants.THUMBNAIL_WIDTH}px`,
													verticalAlign: 'middle',
													textAlign: 'center',
													color: 'rgb(246, 246, 154)',
												}}
												title={
													context.images[imageDate].thumbnails[selected].caption === 'Data Unavailable'
														? 'No Satellite Imagery'
														: context.images[imageDate].thumbnails[selected].caption === 'Planet Available'
														? 'Planet True Colour imagery is available'
														: 'Analysis is unavailable due to excessive cloud coverage'
												}>
												{context.images[imageDate].thumbnails[selected].caption}
											</span>
										</div>
									)}
									<span className="imageryDate" title={hasPlanet ? 'Planet True Colour Available' : ''}>
										{context.images[imageDate].date}
										{hasPlanet ? (<>&nbsp;<span className='planet'>P</span></>) : ''}
									</span>
								</span>
							</SwiperSlide>,
						];
					}
				}

				return imageryCols;
			},
			setStartMonthFilter = (date) => {
				if (date) {
					context.setStartMonthFilter(date);
				}
			},
			setStartDate = (date) => {
				if (!date) return;
				if (!context.exportLocationData) {
					context.setDateRange([date, null]);
					context.updateLocation();
				} else {
					context.setExportRange([date, null]);
				}
			},
			setEndDate = (date) => {
				if (!date) return;
				if (!context.exportLocationData) {
					context.setDateRange([null, date]);
					context.updateLocation();
				} else {
					context.setExportRange([null, date]);
				}
			},
			maxSlides = parseInt((window.innerWidth - context.swiperDecorationsLength) / context.swiperDivisor),
			[startDate, endDate] = [context.location.dateRange[0].start_date, context.location.dateRange[0].end_date],
			datePicker = (
				<DatePicker
					calendarClassName="startMonthFilter"
					dateFormat="MMMM yyyy"
					showMonthYearPicker
					minDate={context.location.dateRange[0].start_date}
					maxDate={isBefore(new Date(), context.location.dateRange[0].end_date) ? endOfMonth(new Date()) : context.location.dateRange[0].end_date}
					selected={context.location.endDate}
					startDate={new Date()}
					onChange={setStartMonthFilter}
					onBlur={setStartMonthFilter}
					popperModifiers={[
						{
							name: 'offset',
							options: {
								offset: [130, -40],
							},
						},
					]}
					showPopperArrow={false}
				/>
			),
			datePickerStart = (
				<DatePicker
					calendarClassName={context.modifyLocation ? 'dateRangeStart' : 'timeSeriesStart'}
					minDate={context.modifyLocation ? startOfYear(new Date(2016, 1, 1)) : startDate}
					maxDate={context.modifyLocation ? new Date() : endDate}
					selected={context.exportRange.startDate || startDate}
					// showMonthDropdown={true}
					// showYearDropdown={true}
					onChange={setStartDate}
					popperModifiers={[
						{
							name: 'offset',
							options: {
								offset: [130, -40],
							},
						},
					]}
					showPopperArrow={false}
					peekNextMonth={true}
					showMonthDropdown={true}
					showYearDropdown={true}
					dropdownMode="select"
				/>
			),
			datePickerEnd = (
				<DatePicker
					calendarClassName={context.modifyLocation ? 'dateRangeEnd' : 'timeSeriesEnd'}
					minDate={context.modifyLocation ? startOfYear(new Date(2016, 1, 1)) : startDate}
					maxDate={context.modifyLocation ? endOfYear(addDays(new Date(), { years: 1 })) : endDate}
					selected={context.exportRange.endDate || endDate}
					onChange={setEndDate}
					popperModifiers={[
						{
							name: 'offset',
							options: {
								offset: [130, -40],
							},
						},
					]}
					showPopperArrow={false}
					peekNextMonth={true}
					showMonthDropdown={true}
					showYearDropdown={true}
					dropdownMode="select"
				/>
			),
			close = async () => {
				setSelectedDate('');
				await context.close(false);
			},
			navigator = (
				<>
					<div className="imagerySelector" key="ImagerySelector">
						<button
							id="trueColour"
							className={`imagerySelectorButton imagerySelectorButtonTrueColour ${selected === 'trueColour' ? 'imageryTypeSelected ' : ''}  ${selected === 'trueColour' ? ' cyan' : ' silver'} left`}
							analysis="trueColour"
							onClick={async (event) => await analysesButtonClicked(event, 'trueColour')}>
							{naturalActionButton}
						</button>
						<button id="bgi" className={`imagerySelectorButton imagerySelectorButtonBgi ${selected === 'bgi' ? 'imageryTypeSelected  ' : ''} ${selected === 'bgi' ? 'cyan' : 'silver'} left`} analysis="bgi" onClick={async (event) => await analysesButtonClicked(event, 'bgi')}>
							{bgIndexActionButton}
						</button>
						<button id="bloom" className={`imagerySelectorButton imagerySelectorButtonBloom ${selected === 'bgi' ? 'imageryTypeSelected  ' : ''}  ${selected === 'bloom' ? 'cyan' : 'silver'} left`} analysis="bloom" onClick={async (event) => await analysesButtonClicked(event, 'bloom')}>
							{bloomActionButton}
						</button>
						<span className={`datePickerWrapper startMonth ${context.lastDateDisplayed ? '' : 'disabled'} left`}>
							<label>&nbsp;{monthDisplayLabel}&nbsp;</label>
							{datePicker}
						</span>
						{/*context.hasInsitu && (
							<button id="insitu" className={`imagerySelectorButton btnShowInsitu blue ${context.lastDateDisplayed ? '' : 'disabled'} left`} title="Display In-Situ Data" onClick={context.showInsitu}>
								In-Situ Data
							</button>
						)*/}
						{isBlank(context.user.restrictedAreas) && (
							<>
								{context.userCanNavigate && (
									<>
										<button id="locationClose" className={`imagerySelectorActionButton close blue right ${getIsDisabledClass()} `} title="Close Location" onClick={close}>
											{closeActionLabel}
										</button>
										{context.userIsAdmin && (
											<button id="locationDelete" className={`imagerySelectorActionButton delete red right ${getIsDisabledClass()} `} title="Delete Location" onClick={context.deleteLocation}>
												{deleteActionLabel}
											</button>
										)}
									</>
								)}
								{!exportClicked && (
									<button id="locationModify" className={`imagerySelectorActionButton modify grey right ${getIsDisabledClass()}`} title="Modify Location" onClick={context.modifyLocation}>
										{modifyActionLabel}
									</button>
								)}
								{exportClicked && (
									<button
										id="locationModify"
										className={`imagerySelectorActionButton modify grey right`}
										title="cancel export"
										onClick={async () => {
											await context.updateExportLocationData(false);
											setExportClicked(false);
											setStartDate(startOfYear(new Date(2016, 1, 1)));
											setEndDate(endOfYear(addDays(new Date(), { years: 1 })));
										}}>
										{CanelActionLabel}
									</button>
								)}
								{isAuthorized(context.user, ['expert']) && (
									<button
										id="locationExport"
										className={`imagerySelectorActionButton export ${exportClicked ? 'green' : 'grey'} right ${context.lastDateDisplayed ? '' : 'disabled'}`}
										title="Export Data"
										onClick={async () => {
											if (exportClicked) {
												await context.exportData();
												setExportClicked(false);
												setStartDate(startOfYear(new Date(2016, 1, 1)));
												setEndDate(endOfYear(addDays(new Date(), { years: 1 })));
											} else {
												setExportClicked(true);
												context.updateExportLocationData(true);
											}
										}}>
										{exportActionLabel}
									</button>
								)}
							</>
						)}
						<button id="trends" className={`imagerySelectorButton btnShowTrends blue right ${context.hasTrendDataForNavigator ? '' : 'disabled'} ${getIsDisabledClass()}`} title="Display Trends" onClick={(event) => context.showTrends(event)}>
							{trendsActionLabel}
						</button>

						<button id="timeSeries" className={`imagerySelectorActionButton timeSeries grey right hidden ${context.lastDateDisplayed ? '' : 'disabled'}`} title="Display Time Series" onClick={context.activateTimeSeries}>
							{timeSeriesActionLabel}
						</button>
						<button id="timeSeriesEnd" className={`imagerySelectorActionButton dateRangeControls timeSeriesDateRange timeSeriesEnd right blue hidden ${context.lastDateDisplayed ? '' : 'disabled'}`} onClick={context.stopTimeSeries}>
							{stopActionLabel}
						</button>
						<button id="timeSeriesStart" className={`imagerySelectorActionButton dateRangeControls timeSeriesDateRange timeSeriesStart right blue hidden ${context.lastDateDisplayed ? '' : 'disabled'}`} onClick={context.startTimeSeries}>
							{startActionLabel}
						</button>
						{/*<button id="cancelDateRange" className={`imagerySelectorActionButton dateRangeControls locationDateRange dateRangeCancel right red hidden ${context.lastDateDisplayed ? '' : 'disabled'}`} onClick={context.cancelImagerySelectorAction}>
							Cancel
								</button>*/}
						<span className={`datePickerWrapper dateRanges right ${datesClassNameRef.current}`}>
							<label>{startLabel}&nbsp;</label>
							{datePickerStart}
							<label>&nbsp;&nbsp;{endLabel}&nbsp;</label>
							{datePickerEnd}
						</span>
					</div>

					<div className="imagery" key="imagery">
						<div className="imageryLeft hidden" key="imageryLeft" onClick={() => context.scrollLeft()}>
							&lt;
						</div>
						<Swiper
							init={true}
							slidesPerView={maxSlides}
							maxBackfaceHiddenSlides={maxSlides}
							slidesOffsetBefore={Constants.SWIPER_SLIDE_FIRST_OFFSET}
							spaceBetween={Constants.SWIPER_SLIDE_MARGIN}
							preventClicks={true}
							preloadImages={true}
							grabCursor={true}
							direction="horizontal"
							navigation={true}
							modules={[Navigation]}>
							{createImageryContent()}
						</Swiper>
						<div className="imageryRight hidden" key="imageryRight" onClick={() => context.scrollRight()}>
							&gt;
						</div>
					</div>
				</>
			);

		return navigator;
	};

	if (context.mode !== Constants.MODES.None && context.locationIndex >= 0 && context.hasImagery) {
		return (
			<React.Fragment key="navigator">
				<div className="navigator">{viewNavigator()}</div>
			</React.Fragment>
		);
	}

	return <></>;
}

export { Navigator };
