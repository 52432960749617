export const bgiColors = [
	// {
	// 	title: 'Bloom',
	// 	style: { backgroundColor: '#358376' },
	// },

	{
		title: 'Clouds',
		style: { backgroundColor: '#D1D4CC' },
	},
	{
		title: 'Interferences',
		style: { backgroundColor: '#A7A19F' },
	},
];

export const bgiColorsNumbers = [
	{
		title: '0-25',
		style: { backgroundColor: 'rgba(120, 129, 228, 0.90)' },
	},
	{
		title: '25-50',
		style: { backgroundColor: 'rgba(47, 220, 57, 0.90)' },
	},
	{
		title: '50-75',
		style: { backgroundColor: '#DDDF38' },
	},
	{
		title: '75-150',
		style: { backgroundColor: '#D19A2C' },
	},
	{
		title: '150-350',
		style: { backgroundColor: '#A13F1D' },
	},
	{
		title: '350+',
		style: { backgroundColor: '#C11A1D' },
	},
];
