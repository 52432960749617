import React from 'react';

const { Tooltip } = require('@mui/material');
const { PickersDay } = require('@mui/x-date-pickers');

export default function CustomizedDate(props) {
	const { disabled, bloomspread, clouds, bloomlevel, planetindex, hasplanet } = props;
	let tooltipTitle;

	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
		setTimeout(() => {
			handleClose();
		}, 5000);
	};

	let planetTooltip;

	if (planetindex > -1) {
		planetTooltip = (
			<>
				{' '}
				<br /> {'Planet:'} <br /> {'True color imagery available'}{' '}
			</>
		);
	} else {
		planetTooltip = (
			<>
				{' '}
				<br /> {'Planet:'} <br /> {'Imagery not available'}{' '}
			</>
		);
	}

	if (!hasplanet) {
		planetTooltip = '';
	}

	if (isNaN(clouds) && isNaN(bloomlevel) && isNaN(bloomspread)) {
		tooltipTitle = (
			<span>
				{'Sentinel 3:'}
				<br />
				{'No data available.'}
				<br />
				{'No Satellite imagery available.'}
				{planetTooltip}
			</span>
		);
	} else if (clouds || bloomlevel || bloomspread) {
		tooltipTitle = (
			<span>
				{'Sentinel 3:'}
				<br />
				Bloom Level :{(Math.round(bloomlevel * 100) / 100).toFixed(2)}
				<br />
				Bloom Spread :{(Math.round(bloomspread * 100) / 100).toFixed(2)}
				{'%'}
				<br />
				Clouds: {(Math.round(clouds * 100) / 100).toFixed(2)}
				{'%'}
				{planetTooltip}
			</span>
		);
	}

	if (!tooltipTitle) {
		tooltipTitle = (
			<>
				{'No Data Available.'} <br /> {`No Satellite Imagery Available. `}
				{planetTooltip}
			</>
		);
	}

	return (
		<Tooltip title={tooltipTitle} placement="top-start" open={open} onClose={handleClose} onOpen={handleOpen}>
			<span>
				<PickersDay {...props} style={disabled ? { textDecoration: 'line-through' } : { color: props.selected ? 'white' : 'rgba(0, 0, 0, 0.75)' }} />
			</span>
		</Tooltip>
	);
}
