
/**
 * Description of HUDControl.js
 *
 * This module is a mapbox control 
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import { defer } from 'lodash';

class HUDControl {

	update = async (content) => {
		let exit = false;
		this.hudSpan.innerHTML = content;
		
		defer(() => exit = true);
		
		while (!exit) {
			await new Promise((resolve) => setTimeout(resolve, 100));
		}
	};

	hide() {
		this.hudControl.classList.add('hidden');
	};

	show() {
		this.hudControl.classList.remove('hidden');
	};

	onAdd(map) {
		const _this = this;
		_this.map = map;
		_this.hudControl = document.createElement('div');
		_this.hudControl.id = 'hud';
		_this.hudSpan = document.createElement('span');
		_this.hudSpan.innerHTML = _this.content;
		_this.hudControl.className = `hud${_this.content ? '' : ' hidden'}`;
		_this.hudControl.appendChild(_this.hudSpan);
		_this.container = document.createElement('div');
		_this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-hudControl';
		_this.container.appendChild(_this.hudControl);
		return _this.container;
	};

	onRemove() {
		this.container.parentNode.removeChild(this.container);
		this.map = undefined;
	};
}

export default HUDControl;